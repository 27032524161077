@charset "utf-8";
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// 各種変数の設定
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//--------------------------------------------------//
// ハックの設定
//--------------------------------------------------//
  @mixin ff {
    @-moz-document url-prefix() {
      @content;
    }
  }
  @mixin safari {
    @at-root _::-webkit-full-page-media, _:future, :root & {
      @content;
    }
  }

//--------------------------------------------------//
// ブレイクポイントの設定
//--------------------------------------------------//
  $xl: 1280px;
  $l:  1024px;
  $m:   960px;
  $s:   768px;
  $xs:  520px;
  @mixin break($breakpoint: m) {
    @if $breakpoint == xl {
      @media screen and (max-width: $xl) { @content; }
    } @else if $breakpoint == l {
      @media screen and (max-width: $l) { @content; }
    } @else if $breakpoint == m {
      @media screen and (max-width: $m) { @content; }
    } @else if $breakpoint == s {
      @media screen and (max-width: $s) { @content; }
    } @else if $breakpoint == xs {
      @media screen and (max-width: $xs) { @content; }
    }
  }

//--------------------------------------------------//
// フォントの設定
//--------------------------------------------------//
  // フォントファミリー
  $ff-base: "メイリオ", "Meiryo", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif; // ベース
  $ff-en: 'Jost', sans-serif; // 英語
  // $ff-min: '', serif; // 明朝
  // フォントサイズ
  $fs-xxs: 1.0rem;
  $fs-xs:  1.2rem;
  $fs-s:   1.4rem;
  $fs-m:   1.6rem;
  $fs-l:   2.0rem;
  $fs-xl:  2.4rem;
  $fs-xxl: 2.8rem;
  $fs-3xl: 3.2rem;
  $fs-4xl: 4.0rem;

//--------------------------------------------------//
// 色の設定（使用しない色は削除）
//--------------------------------------------------//
  $tra : transparent; // 透明
  $aqu : #1aa9db; // 水色
  $l-blu : #E2E8F0; // 青色
  $blu : #4977ad; // 青色
  $d-blu : #153875; // 青色
  $l-grn : #def0ed; // 薄い緑色
  $grn : #22a8a3; // 緑色
  $d-grn : #066f68; // 薄い緑色
  $l-yel-grn : #f3f6e0; // 薄い緑色
  $yel-grn : #7b9e14; // 薄い緑色
  $red : #e63502; // 赤色
  $l-orn : #fff3dd; // オレンジ色
  $orn : #f8b726; // オレンジ色
  $d-orn : #f08306; // オレンジ色
  $blk : #231f20; // 黒色
  $wht : #ffffff; // 白色
  $p-gry: #fafafa; // 薄い灰色
  $l-gry: #eeeeee; // 薄い灰色
  $gry : #cccccc; // 灰色
  $d-gry: #999999; // 濃い灰色
  $colors : (
    'red' : $red,
    'aqu' : $aqu,
    'l-blu' : $l-blu,
    'blu' : $blu,
    'd-blu' : $d-blu,
    'l-grn' : $l-grn,
    'grn' : $grn,
    'd-grn' : $d-grn,
    'l-yel-grn' : $l-yel-grn,
    'yel-grn' : $yel-grn,
    'l-orn' : $l-orn,
    'orn' : $orn,
    'd-orn' : $d-orn,
    'blk' : $blk,
    'wht' : $wht,
    'p-gry': $p-gry,
    'l-gry': $l-gry,
    'gry' : $gry,
    'd-gry': $d-gry
  );

//--------------------------------------------------//
// 画像パスの設定
//--------------------------------------------------//
  // $img-path: "/wp-content/themes/itreat_base/dist";
  // @function printUrl($file-name) {
  //   @return url($img-path + $file-name);
  // }

//--------------------------------------------------//
// 余白の設定
//--------------------------------------------------//
  // デフォルトの値 ※計算用の変数のため削除禁止
  $space: 8px;

  // margin、paddingのmixin
  @mixin mr($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    margin-right: calc(#{$space} * #{$set-size});
    @include break(m) {
      margin-right: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      margin-right: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin mb($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    margin-bottom: calc(#{$space} * #{$set-size});
    @include break(m) {
      margin-bottom: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      margin-bottom: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin ml($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    margin-left: calc(#{$space} * #{$set-size});
    @include break(m) {
      margin-left: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      margin-left: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin p($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    padding: calc(#{$space} * #{$set-size});
    @include break(m) {
      padding: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      padding: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin pt($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    padding-top: calc(#{$space} * #{$set-size});
    @include break(m) {
      padding-top: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      padding-top: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin pr($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    padding-right: calc(#{$space} * #{$set-size});
    @include break(m) {
      padding-right: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      padding-right: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin pb($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    padding-bottom: calc(#{$space} * #{$set-size});
    @include break(m) {
      padding-bottom: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      padding-bottom: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin pl($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    padding-left: calc(#{$space} * #{$set-size});
    @include break(m) {
      padding-left: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      padding-left: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin gap($i-ver: null,$i-hor: null) {
    $set-size-ver: 1;
    $set-size-hor: null;
    @if $i-ver != null {
      $set-size-ver: str-slice($i-ver, 2);
    }
    @if $i-hor != null {
      $set-size-hor: str-slice($i-hor, 2);
    }
    @if $i-hor != null {
      gap: calc(#{$space} * #{$set-size-ver}) calc(#{$space} * #{$set-size-hor});
    } @else {
      gap: calc(#{$space} * #{$set-size-ver});
    }
    @include break(m) {
      @if $i-hor != null {
        gap: calc((#{$space} * #{$set-size-ver}) * 0.75) calc((#{$space} * #{$set-size-hor}) * 0.75);
      } @else {
        gap: calc((#{$space} * #{$set-size-ver}) * 0.75);
      }
    }
    @include break(xs) {
      @if $i-hor != null {
        gap: calc((#{$space} * #{$set-size-ver}) * 0.5) calc((#{$space} * #{$set-size-hor}) * 0.5);
      } @else {
        gap: calc((#{$space} * #{$set-size-ver}) * 0.5);
      }
    }
  }

//--------------------------------------------------//
// wrapの設定
//--------------------------------------------------//
  @mixin wrap($max: null) {
    width: 100%;
    @if $max != null {
      max-width: $max;
    }
    margin-right: auto;
    margin-left: auto;
    padding-right: 40px;
    padding-left: 40px;
    @include break(xl) {
      padding-right: 32px;
      padding-left: 32px;
    }
    @include break(m) {
      padding-right: 24px;
      padding-left: 24px;
    }
    @include break(xs) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

//--------------------------------------------------//
// flexの設定
//--------------------------------------------------//
  @mixin flex($j: null, $a: null, $dir: null, $wrap: wrap) {
    display: flex;
    @if $wrap != null {
      flex-wrap: $wrap;
    }
    @if $j != null and $j != '' {
      @if $j == left {
        justify-content: flex-start;
      } @else if $j == right {
        justify-content: flex-end;
      } @else {
        justify-content: $j;
      }
    }
    @if $a != null and $a != ''  {
      @if $a == top {
        align-items: flex-start;
      } @else if $a == bottom {
        align-items: flex-end;
      } @else {
        align-items: $a;
      }
    }
    @if $dir != null and $dir != '' {
      flex-direction : $dir;
    }
  }

//--------------------------------------------------//
// カラムの設定
//--------------------------------------------------//
@mixin column($num, $gap) {
  $gap-all: #{$gap} * ($num - 1);
  width: calc((100% / #{$num}) - (#{$gap-all} / #{$num}));
}

//--------------------------------------------------//
// ポジションの設定
//--------------------------------------------------//
  @mixin pos($t: null, $r: null, $b: null, $l: null, $z: null, $d: null) {
    @if $d != null {
      position: $d;
    } @else {
      position: absolute;
    }
    @if $t != null and $t != '' {
      top: $t;
    }
    @if $r != null and $r != '' {
      right: $r;
    }
    @if $b != null and $b != '' {
      bottom: $b;
    }
    @if $l != null and $l != '' {
      left: $l;
    }
    @if $z != null {
      z-index: $z;
    }
  }

//--------------------------------------------------//
// 角丸の設定
//--------------------------------------------------//
  @mixin round($i: null) {
    $set-size: 8px;
    @if $i != null {
      $set-size: $i;
    }
    border-radius: #{$set-size};
    @include break(m) {
      border-radius: calc(#{$set-size} * 0.75);
    }
    @include break(xs) {
      border-radius: calc(#{$set-size} * 0.5);
    }
  }

//--------------------------------------------------//
// 文字数制限の設定
//--------------------------------------------------//
  @mixin abbr($row: 1) {
    overflow: hidden;
    @if $row == 1 {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
    } @else {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: $row;
    }
  }


//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// hoverアニメーション
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  @mixin hover-link() {
  }

  @mixin hover-btn() {
  }

  // テキストリンクの設定
  // @mixin link($type: fade, $dir: null, $weight: 1px, $color: $blk) {
  //   @if $dir == null {
  //     @if $type == fade {
  //       $dir: 'top';
  //     } @else if $type == slide {
  //       $dir: 'center';
  //     }
  //   }
  //   position: relative;
  //   display: inline-block;
  //   &::after {
  //     content: '';
  //     position: absolute;
  //     left: 0;
  //     width: 100%;
  //     height: $weight;
  //     background: $color;
  //     @if $type == fade { //フェードの場合
  //       transition: .3s;
  //       opacity: 0;
  //       @if $dir == bottom {
  //         bottom: -2 - $weight;
  //       } @else {
  //         bottom: $weight;
  //       }
  //     } @else if $type == slide { //スライドの場合
  //       bottom: 1 - $weight;
  //       transform: scale(0, 1);
  //       transition: transform .3s;
  //       @if $dir == right {
  //         transform-origin: left top;
  //       } @else if $dir == left {
  //         transform-origin: right top;
  //       } @else {
  //         transform-origin: center top;
  //       }
  //     }
  //   }
  //   &:hover::after {
  //     @if $type == fade {
  //       bottom: 1 - $weight;
  //       opacity: 1;
  //     } @else if $type == slide {
  //       @if $dir == left {
  //         transform-origin: left top;
  //       } @else if $dir == right {
  //         transform-origin: right top;
  //       }
  //       transform: scale(1, 1);
  //     }
  //   }
  // }

  // 背景が反転
  // @mixin btn-reverse($c: $blk, $hc: $wht) {
  //   background: $c;
  //   &:hover {
  //     background: $hc;
  //   }
  // }

  // 背景がスライド
  // @mixin btn-slide($c: $blk, $hc: $wht, $dir: top) {
  //   overflow: hidden;
  //   position: relative;
  //   z-index: 1;
  //   background: $c;
  //   &::before {
  //     content: '';
  //     @include pos($t:0,$l:0,$z:-1);
  //     background: linear-gradient(to $dir, $c 50%, $hc 50%);
  //     @if $dir == left or $dir == right {
  //       width: 200%;
  //       height: 100%;
  //     }
  //     @if $dir == top or $dir == bottom {
  //       width: 100%;
  //       height: 200%;
  //     }
  //     @if $dir == left {
  //       transform: translateX(-50%);
  //     } @else if $dir == right {
  //       transform: translateX(0);
  //     } @else if $dir == top {
  //       transform: translateY(-50%);
  //     } @else if $dir == bottom {
  //       transform: translateY(0);
  //     }
  //     transition: transform .3s cubic-bezier(0.8, 0, 0.2, 1);
  //   }
  //   &:hover {
  //     &::before {
  //       @if $dir == left {
  //         transform: translateX(0);
  //       } @else if $dir == right {
  //         transform: translateX(-50%);
  //       } @else if $dir == top {
  //         transform: translateY(0);
  //       } @else if $dir == bottom {
  //         transform: translateY(-50%);
  //       }
  //     }
  //   }
  // }

  // 押し込み
  // @mixin btn-push($c: $c-main, $hc: $c-sub, $s: $blk) {
  //   @if $c == "" {
  //     $c: $c-main;
  //   }
  //   @if $hc == "" {
  //     $hc: $c-sub;
  //   }
  //   @if $s == "" {
  //     $s: $blk;
  //   }
  //   background: $c;
  //   box-shadow: 0 4px 0 $s;
  //   &:hover {
  //     background: $hc;
  //     transform: translateY(4px);
  //     box-shadow: none;
  //   }
  // }

  // 浮き上がる
  // @mixin btn-float($c: $c-main, $hc: $c-sub, $s: null)  {
  //   @if $c == "" {
  //     $c: $c-main;
  //   }
  //   @if $hc == "" {
  //     $hc: $c-sub;
  //   }
  //   background: $c;
  //   &:hover {
  //     transform: translateY(-5px);
  //     background: $hc;
  //     @if $s == shadow {
  //       box-shadow: 0 6px 14px rgba(0, 0, 0, 0.24);
  //     }
  //   }
  // }

  // 拡大
  // @mixin btn-zoom($c: $c-main, $hc: $c-sub, $s: null) {
  //   @if $c == "" {
  //     $c: $c-main;
  //   }
  //   @if $hc == "" {
  //     $hc: $c-sub;
  //   }
  //   background: $c;
  //   &:hover {
  //     transform: scale(1.1);
  //     background: $hc;
  //     @if $s == shadow {
  //       box-shadow: 0 0 14px rgba(0, 0, 0, 0.24);
  //     }
  //   }
  // }

  // 矢印を動かす
  // @mixin btn-arrow($c: $c-main, $hc: $c-sub, $dir: right) {
  //   @if $c == "" {
  //     $c: $c-main;
  //   }
  //   @if $hc == "" {
  //     $hc: $c-sub;
  //   }
  //   position: relative;
  //   background: $c;
  //   svg {
  //     @if $dir == right {
  //       transform: translateY(-50%);
  //     }
  //     @if $dir == bottom {
  //       transform: translateY(-50%) rotate(90deg);
  //     }
  //     position: absolute;
  //     top: 50%;
  //     right: 16px;
  //     width: 12px;
  //     height: 12px;
  //     fill: $wht;
  //     transition: .3s ease-out;
  //   }
  //   &:hover {
  //     background: $hc;
  //     svg {
  //       @if $dir == right {
  //         right: 10px;
  //       }
  //       @if $dir == bottom {
  //         top: calc( 50% + 6px );
  //       }
  //     }
  //   }
  // }

  // ベタ → 線
  // @mixin btn-trans($c: $c-main, $hc: $c-sub) {
  //   @if $c == "" {
  //     $c: $c-main;
  //   }
  //   @if $hc == "" {
  //     $hc: $c-sub;
  //   }
  //   position: relative;
  //   background: $c;
  //   &::before,
  //   &::after {
  //     position: absolute;
  //     content: "";
  //     width: 0;
  //     height: 2px;
  //     background: $hc;
  //     transition: .3s ease-out;
  //   }
  //   &::before {
  //     top: 0;
  //     right: 0;
  //   }
  //   &::after {
  //     bottom: 0;
  //     left: 0;
  //   }
  //   &:hover {
  //     background: $wht;
  //     color: $hc;
  //     &::before,
  //     &::after {
  //       width: 100%;
  //     }
  //   }
  // }

  // ズレ
  // @mixin btn-shift($c: $c-main, $hc: $c-sub) {
  //   @if $c == "" {
  //     $c: $c-main;
  //   }
  //   @if $hc == "" {
  //     $hc: $c-sub;
  //   }
  //   position: relative;
  //   background: $c;
  //   z-index: auto;
  //   &::before,
  //   &::after {
  //     position: absolute;
  //     content: "";
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     transition: .3s ease-out;
  //   }
  //   &::before {
  //     border: solid 2px $hc;
  //     z-index: -1;
  //   }
  //   &::after {
  //     background: rgba($hc,.16);
  //     z-index: -2;
  //   }
  //   &:hover {
  //     background: rgba($c,0);
  //     color: $hc;
  //     &::after {
  //       top: 6px;
  //       left: 6px;
  //     }
  //   }
  // }