//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// 1.クラス名の付け方について
// BEM記法はクラス名が長くなりやすいため単語は極力短縮してください。
// ※ただし、短くしすぎて他者に伝わらない場合があるので注意してください。
//
// 例：よく使う単語
// 見出し　　　→　[NG] title    [OK] ttl
// テキスト　　→　[NG] text     [OK] txt
// ボタン　　　→　[NG] button   [OK] btn
//
// 2.接頭辞について
// 下記のものについては接頭辞を使用して他のクラスと差別化してください。
//
// 例：
// 状態を表すためのクラス　　→　is-current,is-hidden 等
// スクリプト用のクラス　　　→　js-tab,js-acd 等
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// パーツ読み込み
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  @import "_variable.scss"; // 変数、mixin
  @import "_reset.scss"; // リセット
  @import "_default.scss"; // 初期設定
  @import "_animation.scss"; // アニメーション
  @import '_header.scss'; // ヘッダー
  @import '_footer.scss'; // フッター
  @import '_notfound.scss'; // 404ページ


//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// 汎用パーツ
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//--------------------------------------------------//
// 下層共通
//--------------------------------------------------//
  .lower-page {
    // メインビジュアル
    .hero {
      overflow: hidden;
      @include flex(center,center);
      width: 100%;
      height: 400px;
      @include mb(x2);
      background: $gry;
    }
    // パンくず
    .breadcrumb {
      @include wrap(1280px);
      @include mb(x10);
      &__item {
        display: inline;
        &:not(:nth-last-of-type(1)) {
          position: relative;
          &::after {
            content: '>';
            padding: 0 4px 0 8px;
          }
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    // ページ見出し
    .pagettl {
      color: $wht;
      font-size: $fs-4xl;
    }
    // セクション見出し
    .headline {
      @include mb(x4);
      font-size: $fs-3xl;
    }
    // 大見出し
    .ttl-l {
      @include mb(x3);
      font-size: $fs-xxl;
    }
    // 中見出し
    .ttl-m {
      @include mb(x2);
      font-size: $fs-xl;
    }
    // 小見出し
    .ttl-s {
      @include mb(x1);
      font-size: $fs-l;
    }
  }

//--------------------------------------------------//
// 段落（pタグ）
//--------------------------------------------------//
  // 通常
  .txt {
    &.--center {
      text-align: center;
    }
    &.--right {
      text-align: right;
    }
  }
  // リード文
  .lead-txt {
    font-size: $fs-l;
    &.--center {
      text-align: center;
    }
    &.--right {
      text-align: right;
    }
  }
  // リンク付き
  .link-txt {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  // 注釈（※）付き
  .note-txt {
    margin-left: 1em;
    font-size: $fs-s;
    text-indent: -1em;
    &::before {
      content: '※';
    }
  }
  // フォントカラー
  @each $name, $color in $colors {
    .fc-#{$name} {
      color: $color;
    }
  }

//--------------------------------------------------//
// リスト（ul,olタグ）
//--------------------------------------------------//
  // 通常
  .list {
    &__item {
      position: relative;
      padding-left: 16px;
      &:not(:nth-last-of-type(1)) {
        margin-bottom: 8px;
      }
      &::before {
        content: '';
        display: block;
        @include pos($t:8px,$l:0);
        width: 8px;
        height: 8px;
        background: $blk;
        border-radius: 50%;
      }
    }
  }
  // 番号付き
  .num-list {
    counter-reset: list-num;
    &__item {
      margin-left: 1em;
      text-indent: -1em;
      &::before {
        counter-increment: list-num;
        content: counter(list-num)'.';
      }
    }
  }
  // 注釈（※）付き
  .note-list {
    &__item {
      margin-left: 1em;
      font-size: $fs-xs;
      text-indent: -1em;
      &::before {
        content: '※';
      }
    }
  }

//--------------------------------------------------//
// ボタン
//--------------------------------------------------//
  // 通常
  .btn {
    @include flex(center);
    gap: 24px;
    &.--left {
      justify-content: flex-start;
    }
    &.--right {
      justify-content: flex-end;
    }
    a {
      @include flex(center,center);
      position: relative;
      width: 100%;
      max-width: 300px;
      min-height: 40px;
      padding: 9px 64px 7px 8px;
      background: $wht;
      border: solid 1px $blk;
      border-radius: 2px;
      color: $blk;
      font-size: $fs-s;
      line-height: 1.2;
      @include break(xs) {
        padding: 9px 40px 7px 8px;
      }
      .ico-arrow {
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 6px;
        fill: $blk;
        @include break(xs) {
          width: 20px;
          height: 5px;
          right: 12px;
        }
      }
      &:hover {
        background: $blk;
        color: $wht;
        .ico-arrow {
          fill: $wht;
        }
      }
      // ボタンカラー
      @each $name, $color in $colors {
        &.bc-#{$name} {
          background: $color;
          &:hover {
            background: $wht;
            color: $color;
          }
        }
      }
    }
  }
  // ページトップ
  .pagetop {
    right: 20px;
    bottom: calc( 0px + env(safe-area-inset-bottom));
    width: 64px;
    height: 64px;
    cursor: pointer;
    z-index: 9900;
  }

//--------------------------------------------------//
// 表（tableタグ）
//--------------------------------------------------//
  // 通常
  .tbl {
    table {
      width: 100%;
      border-top: solid 1px $gry;
      border-left: solid 1px $gry;
      thead {
        tr {
          th {
            padding: 16px;
            background: lighten($blk, 40%);
            border-right: solid 1px $gry;
            border-bottom: solid 1px $gry;
            color: $blk;
          }
        }
        @include break {
          display: none;
        }
      }//thead
      tbody {
        @include break {
          display: block;
        }
        tr {
          @include break {
            display: block;
          }
          th,
          td {
            padding: 16px;
            border-right: solid 1px $gry;
            border-bottom: solid 1px $gry;
            @include break {
              display: block;
            }
          }
          th {
            background: $l-gry;
          }
          td {
            &::before {
              @include break {
                content: attr(tbl-data);
                display: block;
                color: $blk;
              }
            }
          }
        }
      }//tbody
    }
  }
  // スクロール ※使用しない場合は削除
  .scroll-tbl {
    overflow-x: auto;
    table {
      width: 100%;
      border-top: solid 1px $gry;
      border-left: solid 1px $gry;
      thead {
        tr {
          th {
            padding: 16px;
            background: lighten($blk, 40%);
            border-right: solid 1px $gry;
            border-bottom: solid 1px $gry;
            color: $blk;
            white-space: nowrap;
          }
        }
      }//thead
      tbody {
        tr {
          th,
          td {
            padding: 16px;
            border-right: solid 1px $gry;
            border-bottom: solid 1px $gry;
          }
          th {
            background: $l-gry;
          }
          td {
          }
        }
      }//tbody
    }//table
  }
  // 診療時間表
  .hours-tbl {
    table {
      width: 100%;
      border-top: solid 1px $gry;
      border-left: solid 1px $gry;
      thead {
        tr {
          th {
            padding: 16px;
            background: lighten($blk, 40%);
            border-right: solid 1px $gry;
            border-bottom: solid 1px $gry;
            color: $blk;
          }
        }
        @include break {
          display: none;
        }
      }
      tbody {
        @include break {
          display: block;
        }
        tr {
          @include break {
            display: block;
          }
          th,
          td {
            padding: 16px;
            border-right: solid 1px $gry;
            border-bottom: solid 1px $gry;
            @include break {
              display: block;
            }
          }
          th {
            background: $l-gry;
          }
          td {
            &::before {
              @include break {
                content: attr(tbl-data);
                display: block;
                color: $blk;
              }
            }
          }
        }
      }//tbody
    }
  }

//--------------------------------------------------//
// 枠
//--------------------------------------------------//
  .frame {
    padding: 16px;
    border: solid 1px $gry;
  }

//--------------------------------------------------//
// スライダー ※使用しない場合は削除
//--------------------------------------------------//
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    display: block;
    position: relative;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    &:before,
    &:after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
    .slick-loading & {
      visibility: hidden;
    }
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
      float: right;
    }
    img {
      display: block;
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }
    .slick-initialized & {
      display: block;
    }
    .slick-loading & {
      visibility: hidden;
    }
    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

//--------------------------------------------------//
// モーダル ※使用しない場合は削除
//--------------------------------------------------//
  .modal {
    .modal-switch {
      display: none;
    }
    // オーバーレイ
    @at-root .modal-overlay {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9997;
      width: 100vw;
      height: 100vh;
      background: $blk;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      .modal-switch:checked ~ & {
        opacity: 0.3;
        visibility: visible;
      }
    }
    // モーダルコンテンツ
    @at-root .modal-content {
      opacity: 0;
      visibility: hidden;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 9998;
      width: calc(100% - 80px);
      max-width: 900px;
      max-height: calc(100% - 80px);
      padding: 56px 24px 24px;
      background: $wht;
      transform: translate(-50%,-50%) scale(0);
      @include break(xl) {
        width: calc(100% - 64px);
      }
      @include break {
        width: calc(100% - 48px);
        padding: 48px 20px 20px;
      }
      @include break(xs) {
        width: calc(100% - 32px);
        padding: 40px 16px 16px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      img {
        width: auto;
        max-height: 100%;
        margin: 0 auto;
      }
      .modal-switch:checked ~ & {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%,-50%) scale(1);
        transition: opacity .3s,  visibility .3s, transform 0s;
      }
    }
    // 閉じるボタン
    .modal-close {
      @include pos($t:16px,$r:24px,$z:9999);
      font-size: $fs-3xl;
      line-height: 32px;
      transition: 0.3s ease-out;
      cursor: pointer;
      @include break {
        right: 20px;
        font-size: $fs-xl;
        line-height: 24px;
      }
      @include break(xs) {
        top: 12px;
        right: 16px;
        font-size: $fs-l;
        line-height: 20px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }

//--------------------------------------------------//
// アコーディオン ※使用しない場合は削除
//--------------------------------------------------//
  .acd {
    summary {
      position: relative;
      @include p(x3);
      list-style: none;
      cursor: pointer;
      &::-webkit-details-marker {
        display: none;
      }
      &::after {
        content: '+';
        @include pos($t:50%,$r:30px);
        font-size: $fs-3xl;
        line-height: 32px;
        transform: translateY(-50%);
        transition: 0.3s ease-out;
      }
    }
    .acd-content {
      @include p(x3);
      background: $l-gry;
    }
    &[open] {
      summary {
        &::after {
          transform: translateY(-50%) rotate(45deg);
        }
      }
      .acd-content {
        animation: fadeIn 0.3s ease-in-out;
      }
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

//--------------------------------------------------//
// 署名 ※使用しない場合は削除
//--------------------------------------------------//
  .writer {
    @include flex(space-between);
    padding: 16px;
    border: solid 2px $l-gry;
    .writer-vsl {
      width: 96px;
      @include break(m) {
        width: 88px;
      }
      @include break(s) {
        width: 80px;
      }
      @include break(xs) {
        width: 104px;
        margin: 0 auto 16px;
      }
    }
    .writer-dtl {
      width: calc(100% - 112px);
      @include break(m) {
        width: calc(100% - 104px);
      }
      @include break(s) {
        width: calc(100% - 96px);
      }
      @include break(xs) {
        width: 100%;
      }
      &__ttl {
        display: inline-block;
        margin-bottom: 4px;
        padding: 2px 8px;
        background: $blk;
        color: $wht;
        @include break(xs) {
          width: 100%;
          margin-bottom: 8px;
          text-align: center;
        }
      }
      &__name {
        margin-bottom: 4px;
        @include break(xs) {
          text-align: center;
        }
        a {
          color: $blk;
        }
      }
      &__company {
        font-size: $fs-s;
        @include break(xs) {
          text-align: center;
        }
      }
      &__post {
        margin-bottom: 8px;
        font-size: $fs-s;
        @include break(xs) {
          text-align: center;
        }
      }
      &__txt {
        font-size: $fs-s;
      }
    }
  }