//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// スクロールアニメーション
// https://animista.net/
//
// アニメーションを追加するときの注意点
// ・opacityは共通で設定しているため、個別では設定しないようにしてください。
//
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//--------------------------------------------------//
// 共通設定
//--------------------------------------------------//
  //animationタグのオプション
  @mixin option-animation {
    animation-duration: 0.8s; //アニメーション完了までの時間
    animation-timing-function: ease-in-out; //アニメーション中の速度
    animation-delay: 0s; //アニメーションの開始時間
    animation-iteration-count: 1; //アニメーションの回数（数字 または infinite）
    animation-fill-mode: both; //アニメーション開始時と終了時の状態を設定
  }
  //transitionタグのオプション
  @mixin option-transition {
    transition-property: all; //トランジションを与えるプロパティの設定（複数指定はカンマ区切り）
    transition-duration: 0.8s; //トランジション完了までの時間
    transition-timing-function: ease-in-out; //トランジション中の速度
    transition-delay: 0s; //トランジションの開始時間
  }

  [anime] {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
    &.is-animated {
      opacity: 1;
    }
  }

//--------------------------------------------------//
// 1. 拡大
//--------------------------------------------------//
  //中心
  [anime*="scale"].is-animated {
    animation-name: scale;
    @include option-animation;
  }
  //上から
  [anime*="scale-top"].is-animated {
    animation-name: scale;
    @include option-animation;
    transform-origin: 50% 0%;
  }
  //右から
  [anime*="scale-right"].is-animated {
    animation-name: scale;
    @include option-animation;
    transform-origin: 100% 50%;
  }
  //下から
  [anime*="scale-bottom"].is-animated {
    animation-name: scale;
    @include option-animation;
    transform-origin: 50% 100%;
  }
  //左から
  [anime*="scale-left"].is-animated {
    animation-name: scale;
    @include option-animation;
    transform-origin: 0% 50%;
  }
  @keyframes scale {
    0%   { transform: scale(0); }
    100% { transform: scale(1); }
  }

//--------------------------------------------------//
// 2. フェード
//--------------------------------------------------//
  //前
  [anime*="fade-front"].is-animated {
    animation-name: fade-front;
    @include option-animation;
  }
  @keyframes fade-front {
    0%   { transform: scale(0.9); }
    100% { transform: scale(1); }
  }
  //後
  [anime*="fade-back"].is-animated {
    animation-name: fade-back;
    @include option-animation;
  }
  @keyframes fade-back {
    0%   { transform: scale(1.1); }
    100% { transform: scale(1); }
  }

//--------------------------------------------------//
// 3. スライド
//--------------------------------------------------//
  //上から
  [anime*="slide-top"].is-animated {
    animation-name: slide-top;
    @include option-animation;
  }
  @keyframes slide-top {
    0%   { transform: translateY(-200px); }
    100% { transform: translateY(0); }
  }
  //右から
  [anime*="slide-right"].is-animated {
    animation-name: slide-right;
    @include option-animation;
  }
  @keyframes slide-right {
    0%   { transform: translateX(200px); }
    100% { transform: translateX(0); }
  }
  //下から
  [anime*="slide-bottom"].is-animated {
    animation-name: slide-bottom;
    @include option-animation;
  }
  @keyframes slide-bottom {
    0%   { transform: translateY(200px); }
    100% { transform: translateX(0); }
  }
  //左から
  [anime*="slide-left"].is-animated {
    animation-name: slide-left;
    @include option-animation;
  }
  @keyframes slide-left {
    0%   { transform: translateX(-200px); }
    100% { transform: translateX(0); }
  }

//--------------------------------------------------//
// 4. フリップ（反転）
//--------------------------------------------------//
  //上から
  [anime*="flip-top"].is-animated {
    animation-name: flip-top;
    @include option-animation;
  }
  @keyframes flip-top {
    0%   { transform: perspective(1000px) rotateX(80deg); }
    100% { transform: perspective(1000px) rotateX(0); }
  }
  //右から
  [anime*="flip-right"].is-animated {
    animation-name: flip-right;
    @include option-animation;
  }
  @keyframes flip-right {
    0%   { transform: perspective(1000px) rotateY(80deg); }
    100% { transform: perspective(1000px) rotateY(0); }
  }
  //下から
  [anime*="flip-bottom"].is-animated {
    animation-name: flip-bottom;
    @include option-animation;
  }
  @keyframes flip-bottom {
    0%   { transform: perspective(1000px) rotateX(-80deg); }
    100% { transform: perspective(1000px) rotateX(0); }
  }
  //左から
  [anime*="flip-left"].is-animated {
    animation-name: flip-left;
    @include option-animation;
  }
  @keyframes flip-left {
    0%   { transform: perspective(1000px) rotateY(-80deg); }
    100% { transform: perspective(1000px) rotateY(0); }
  }

//--------------------------------------------------//
// 5. バウンド（弾む）
//--------------------------------------------------//
  //上から
  [anime*="bound-top"].is-animated {
    animation-name: bound-top;
    @include option-animation;
  }
  @keyframes bound-top {
    0%   { transform: translateY(-48px); animation-timing-function: ease-in; }
    40%  { transform: translateY(-24px); animation-timing-function: ease-in; }
    64%  { transform: translateY(-12px); animation-timing-function: ease-in; }
    82%  { transform: translateY(-6px); animation-timing-function: ease-in; }
    94%  { transform: translateY(-4px); animation-timing-function: ease-in; }
    25%,
    56%,
    74%,
    88%,
    100% { transform: translateY(0px); animation-timing-function: ease-out; }
  }
  //右から
  [anime*="bound-right"].is-animated {
    animation-name: bound-right;
    @include option-animation;
  }
  @keyframes bound-right {
    0%   { transform: translateX(48px); animation-timing-function: ease-in; }
    40%  { transform: translateX(24px); animation-timing-function: ease-in; }
    64%  { transform: translateX(12px); animation-timing-function: ease-in; }
    82%  { transform: translateX(6px); animation-timing-function: ease-in; }
    94%  { transform: translateX(4px); animation-timing-function: ease-in; }
    25%,
    56%,
    74%,
    88%,
    100% { transform: translateX(0px); animation-timing-function: ease-out; }
  }
  //下から
  [anime*="bound-bottom"].is-animated {
    animation-name: bound-bottom;
    @include option-animation;
  }
  @keyframes bound-bottom {
    0%   { transform: translateY(48px); animation-timing-function: ease-in; }
    40%  { transform: translateY(24px); animation-timing-function: ease-in; }
    64%  { transform: translateY(12px); animation-timing-function: ease-in; }
    82%  { transform: translateY(6px); animation-timing-function: ease-in; }
    94%  { transform: translateY(4px); animation-timing-function: ease-in; }
    25%,
    56%,
    74%,
    88%,
    100% { transform: translateY(0px); animation-timing-function: ease-out; }
  }
  //左から
  [anime*="bound-left"].is-animated {
    animation-name: bound-left;
    @include option-animation;
  }
  @keyframes bound-left {
    0%   { transform: translateX(-48px); animation-timing-function: ease-in; }
    40%  { transform: translateX(-24px); animation-timing-function: ease-in; }
    64%  { transform: translateX(-12px); animation-timing-function: ease-in; }
    82%  { transform: translateX(-6px); animation-timing-function: ease-in; }
    94%  { transform: translateX(-4px); animation-timing-function: ease-in; }
    25%,
    56%,
    74%,
    88%,
    100% { transform: translateX(0px); animation-timing-function: ease-out; }
  }

//--------------------------------------------------//
// 6. フォーカス
//--------------------------------------------------//
  [anime*="focus"].is-animated {
    animation-name: focus;
    @include option-animation;
  }
  @keyframes focus {
    0%   { filter: blur(12px); }
    100% { filter: blur(0px); }
  }

//--------------------------------------------------//
// 7. テキスト
//--------------------------------------------------//
  //伸びる
  [anime*="txt-grow"].is-animated {
    animation-name: txt-grow;
    @include option-animation;
  }
  @keyframes txt-grow {
    0%   { letter-spacing: -0.8em; }
    100% { letter-spacing: normal; }
  }
  //縮む
  [anime*="txt-shrink"].is-animated {
    animation-name: txt-shrink;
    @include option-animation;
  }
  @keyframes txt-shrink {
    0%   { letter-spacing: 0.8em; }
    100% { letter-spacing: normal; }
  }

//--------------------------------------------------//
// その他のアニメーション
//--------------------------------------------------//
  //例：transitionを使用した記述例
  [anime*="roll"] {
    transform: rotateZ(0);
    @include option-transition;
    &.is-animated {
      transform: rotateZ(360deg);
    }
  }