//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// ヘッダー
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  .header {
    position: fixed;
    @include flex("", center);
    top: 0;
    left: 0;
    width: 100%;
    padding: 24px 40px 0;
    height: 110px;
    z-index: 9999;
    transition: 0.3s ease-out;
    @include break(xl) {
      padding: 24px 32px 0;
    }
    @include break(l) {
      padding: 24px 24px 0;
    }
    @include break(m) {
      padding: 16px 24px 0;
      height: 72px;
    }
    @include break(xs) {
      padding: 12px 12px 0;
      height: 56px;
    }
    .header-wrap {
      @include flex(space-between);
      width: 100%;
      @include break(xs) {
        align-items: flex-start;
      }
    }
    // ロゴ
    .header-logo {
      padding-top: 3px;
      width: calc( 100% - 648px );
      max-width: 480px;
      height: 100%;
      @include break(l) {
        width: calc( 100% - 624px );
      }
      @include break(m) {
        width: 100%;
        max-width: 280px;
      }
      @include break(xs) {
        @include flex("", center);
        width: calc( 100% - 152px );
        max-width: 220px;
      }
      a {
        position: relative;
        display: block;
        width: 100%;
        .ico-logo {
          margin-bottom: 4px;
          aspect-ratio: 4/.35;
          fill: $wht;
          @include break(s) {
            margin-bottom: 0;
          }
        }
        .ico-logo-en {
          aspect-ratio: 3/.16;
          fill: $wht;
          width: 71%;
          vertical-align: top
        }
      }
    }
    .header-menu {
      @include flex(space-between, center);
      gap: 12px;
      width: 620px;
      @include break(xl) {
        width: 616px;
      }
      @include break(l) {
        width: 600px;
      }
      @include break(m) {
        width: auto;
        padding-right: 64px
      }
      @include break(xs) {
        padding-right: 40px
      }
      // 言語変更ボタン
      .header-lang {
        .language-box {
          position: relative;
          .language-box-switch {
            display: none;
          }
          .language-box-btn {
            @include flex(center, center);
            width: 82px;
            height: 32px;
            border: solid 1px $wht;
            border-radius: 4px;
            text-align: center;
            font-family: $ff-en;
            line-height: 1;
            color: $wht;
            font-size: 1.3rem;
            cursor: pointer;
            transition: 0.3s ease-out;
            
            @include break(xs) {
              width: 72px;
              height: 28px;
            }
          }
          .language-box-list {
            position: absolute;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease-out;
            &__item {
              overflow: auto;
              a {
                display: block;
                width: 100%;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-color: $wht;
                font-size: $fs-xxs;
                &:hover {
                  background: $l-gry;
                }
              }
            }
          }
          &:hover,
          &:focus-within {
            .language-box-btn {
              border-radius: 4px 4px 0 0;
              background: $wht;
              color: $blu;
            }
            .language-box-list {
              visibility: visible;
              opacity: 1;
              @include break(m) {
                visibility: hidden;
                opacity: 0;
              }
            }
          }
          .language-box-switch:checked ~ .language-box-list {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      // グローバルナビゲーション
      .global-nav {
        padding-right: 48px;
        width: calc( 100% - 98px );
        @include break(xl) {
          padding-right: 44px;
        }
        @include break(m) {
          display: none;
        }
        .global-nav-list {
          display: flex;
          gap: 24px;
          @include break(l) {
            gap: 20px;
          }
          &__item {
            position: relative;
            &:not(:last-of-type) {
              &::after {
                position: absolute;
                content: "";
                top: 50%;
                transform: translateY(-50%);
                right: -12px;
                width: 1px;
                height: 16px;
                background: $wht;
                @include break(l) {
                  right: -10px;
                }
              }
            }
            a {
              display: block;
              color: $wht;
              font-size: 1.3rem;
              &:hover {
                transform: translateY(2px);
              }
            }
          }
        }
      }
      .header-search {
        width: 100%;
        max-width: 480px;
        margin-left: auto;
        @include break(m) {
          display: none;
        }
      }
    }
    //追尾ヘッダー -----//
    &.float-header {
      padding: 0 40px;
      align-items: center;
      background: $wht;
      box-shadow: 0 5px 12px rgba($blk, .06);
      @include break(xl) {
        padding: 0 32px;
      }
      @include break(l) {
        padding: 0 24px;
      }
      @include break(xs) {
        padding: 0 12px;
      }
      .header-wrap {
        align-items: center;
      }
      .header-logo {
        padding-top: 13px;
        @include break(s) {
          padding-top: 10px;
        }
        a {
          .ico-logo {
            margin-bottom: 0;
            max-width: 400px;
            max-height: 35px;
            fill: $blu;
            @include break(l) {
              max-width: 297px;
              max-height: 26px;
            }
          }
          .ico-logo-en {
            max-width: 280px;
            max-height: 15px;
            fill: #aaa;
            @include break(l) {
              max-width: 206px;
              max-height: 11px;
            }
          }
        }
      }
      .header-menu {
        gap: 8px 12px;
        .header-lang {
          .language-box {
            .language-box-btn {
              border-color: $blu;
              color: $blu;
            }
          }
        }
        // グローバルナビゲーション
        .global-nav {
          .global-nav-list {
            &__item {
              &:not(:last-of-type) {
                &::after {
                  background: $blu;
                }
              }
              a {
                color: $blu;
              }
            }
          }
        }
        .header-search {
          .search-box .search-box-inner dt input {
            background: $l-gry;
            height: 36px;
          }
        }
      }
    }
  }

//--------------------------------------------------//
// ヘッダー・ドロワー共通 検索ボックス
//--------------------------------------------------//
  .search-box {
    width: 100%;
    .search-box-inner {
      position: relative;
      dt {
        input {
          padding: 0 16px;
          width: 100%;
          height: 40px;
          border-radius: 40px;
          background: rgba($wht, .85);
          font-family: $ff-base;
          line-height: 40px;
        }
      }
      dd {
        button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
          width: 16px;
          height: 16px;
          svg {
            fill: $aqu;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

//--------------------------------------------------//
// ドロワーメニュー
//--------------------------------------------------//
  .drawer {
    @include pos($d:relative,$t:0,$r:0,$z:9999);
    .drawer-switch {
      display: none;
    }
    // ハンバーガー
    @at-root .drawer-hamburger {
      display: block;
      position: fixed;
      top: 29px;
      right: 48px;
      width: 28px;
      height: 22px;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      z-index: 9999;
      @include break(xl) {
        right: 32px;
      }
      @include break(m) {
        top: 32px;
      }
      @include break(xs) {
        top: 16px;
        right: 12px;
      }
      &::before {
        position: absolute;
        content: "";
        @include pos($l:0, $t:50%);
        transform: translateY(-50%);
        width: 22px;
        height: 2px;
        background: $wht;
      }
      span {
        display: block;
        position: relative;
        top: 0;
        width: 28px;
        height: 22px;
        margin: 0 auto;
        &::before,
        &::after {
          content: '';
          display: block;
          @include pos($l:0);
          height: 2px;
          background: $wht;
          transition: 0.3s ease-in-out;
        }
        &::before {
          top: 0;
          width: 28px;
        }
        &::after {
          bottom: 0;
          width: 16px;
        }
      }
      &.--active {
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 1;
        }
        span::before {
          top: 50%;
          transform: rotate(45deg) translateY(-50%);
        }
        span::after {
          top: 50%;
          transform: rotate(-45deg) translateY(-50%);
          width: 28px;
        }
      }
    }
    // オーバーレイ
    @at-root .drawer-overlay {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9998;
      width: 100%;
      height: 80px;
      background: $blu;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      @include break(m) {
        height: 92px;
      }
      @include break(xs) {
        height: 56px;
      }
      //トグルボタンがクリックされたら
      &.--active {
        visibility: visible;
        opacity: 1;
      }
    }
    @at-root .drawer-container {
      overflow: auto;
      position: fixed;
      top: 80px;
      left: 0;
      z-index: 9998;
      width: 100%;
      height: calc( 100% - 80px );
      padding: 0 40px 40px;
      background: $blu;
      transform: translateX(-100%);
      transition: 0.3s ease-in-out;
      visibility: hidden;
      @include break(xl) {
        padding: 0 32px 32px;
      }
      @include break(m) {
        top: 92px;
        height: calc( 100% - 92px );
        padding: 0 12px 12px;
      }
      @include break(xs) {
        top: 56px;
        height: calc( 100% - 56px );
        padding: 0 8px 8px;
      }
      //トグルボタンがクリックされたら
      &.--active {
        transform: translateX(0);
        visibility: initial;
      }
    }
    .drawer-search {
      margin-left: auto;
      margin-right: auto;
      @include mb(x5);
      max-width: 640px;
      .search-box .search-box-inner dt input {
        height: 48px;
        @include break(s) {
          height: 40px;
        }
      }
    }
    // ドロワーコンテンツ
    @at-root .drawer-content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;
      z-index: 9997;
      @include break(xl) {
        gap: 16px;
      }
      @include break(m) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include break(xs) {
        gap: 8px;
      }
      &__item {
        padding: 16px;
        background: rgba($wht, .95);
        border-radius: 4px;
        @include break(l) {
          padding: 14px;
        }
        @include break(xs) {
          padding: 14px 10px;
        }
        .drawer-menu-ttl {
          position: relative;
          margin-bottom: 9px;
          padding-bottom: 4px;
          font-weight: bold;
          color: $blu;
          @include break(m) {
            margin-bottom: 16px;
            padding-bottom: 10px;
          }
          @include break(s) {
            font-size: 1.3rem;
          }
          &::before {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient( to right, $blu 40px, $l-gry 41px);
          }
        }
        // 複数列があるメニュー
        .drawer-menu-wrap {
          display: grid;
          gap: 32px;
          @include break(xl) {
            gap: 24px;
          }
          @include break(m) {
            gap: 16px;
          }
          @include break(xs) {
            gap: 16px 10px;
          }
        }
        .drawer-menu {
          &__item {
            a {
              position: relative;
              display: block;
              padding-left: 12px;
              font-size: 1.3rem;
              line-height: 1.2;
              &::before {
                position: absolute;
                content: "";
                top: 4px;
                left: 0;
                transform: rotateZ(45deg);
                width: 5px;
                height: 5px;
                border-style: solid;
                border-width: 1px 1px 0 0;
                border-color: $blu $blu $tra $tra;
              }
              &:hover {
                transform: translateX(2px);
                opacity: .8;
              }
            }
            &:not(:last-child) {
              margin-bottom: 10px;
              @include break(m) {
                margin-bottom: 14px;
              }
              @include break(xs) {
                margin-bottom: 12px;
              }
            }
          }
        }
        &:nth-child(3) {
          grid-column: 3 / 5;
          @include break(m) {
            grid-column: 1 / 3;
          }
          .drawer-menu-wrap {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        &:nth-child(4) {
          grid-column: 1 / 5;
          @include break(m) {
            grid-column: 1 / 3;
          }
          .drawer-menu-wrap {
            grid-template-columns: repeat(4, 1fr);
            @include break(m) {
              grid-template-columns: repeat(2, 1fr);
            }
          }
        }
        &:nth-child(9) {
          grid-column: 1 / 5;
          @include break(m) {
            grid-column: 1 / 3;
          }
          .drawer-menu-wrap {
            grid-template-columns: repeat(7, 1fr);
            @include break(m) {
              grid-template-columns: repeat(3, 1fr);
            }
            @include break(xs) {
              grid-template-columns: repeat(2, 1fr);
            }
          }
        }
        &:nth-child(10) {
          grid-column: 1 / 5;
          @include break(m) {
            grid-column: 1 / 3;
          }
          .drawer-menu-wrap {
            grid-template-columns: repeat(5, 1fr);
            @include break(m) {
              grid-template-columns: repeat(3, 1fr);
            }
            @include break(xs) {
              grid-template-columns: repeat(2, 1fr);
            }
          }
        }
      }
    }
    //追尾するときのドロワー -----//
    &.float-drawer {
      .drawer-hamburger {
        top: 20px;
        @include break(m) {
          top: 24px;
        }
        @include break(xs) {
          top: 17px;
        }
        &::before {
          background: $blu;
        }
        span {
          &::before,
          &::after {
            background: $blu;
          }
        }
        &.--active {
          top: 29px;
          @include break(xs) {
            top: 16px;
          }
          span {
            &::before,
            &::after {
              background: $wht;
            }
          }
        }
      }
    }
  }