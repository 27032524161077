@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select, button, textarea {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus-visible {
  outline: 3px solid #0044ff;
  outline-offset: -1px;
}

html,
body {
  width: 100%;
  height: 100%;
  color: #231f20;
  background: transparent;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  line-height: 1.6;
  font-family: "メイリオ", "Meiryo", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif;
}

@media screen and (max-width: 960px) {
  body {
    letter-spacing: 0;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 1.4rem;
    line-height: 1.4;
    letter-spacing: 0;
  }
}

body.page-overflow {
  overflow: hidden;
  height: 100%;
}

a {
  overflow-wrap: break-word;
  color: #231f20;
  text-decoration: none;
  transition: 0.3s ease-out;
}

a:hover, a:active, a:visited {
  color: #231f20;
}

a[href*="tel:"] {
  text-decoration: none;
  pointer-events: none;
  cursor: default;
}

@media screen and (max-width: 960px) {
  a[href*="tel:"] {
    pointer-events: auto;
    cursor: pointer;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

img:not(.no-fit) {
  width: 100%;
}

@media screen and (min-width: 1281px) {
  .view-xl {
    display: none !important;
  }
}

@media screen and (max-width: 1280px) {
  .none-xl {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .view-l {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .none-l {
    display: none !important;
  }
}

@media screen and (min-width: 961px) {
  .view-m {
    display: none !important;
  }
}

@media screen and (max-width: 960px) {
  .none-m {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .view-s {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .none-s {
    display: none !important;
  }
}

@media screen and (min-width: 521px) {
  .view-xs {
    display: none !important;
  }
}

@media screen and (max-width: 520px) {
  .none-xs {
    display: none !important;
  }
}

.wrap {
  width: 100%;
  max-width: 1180px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
}

@media screen and (max-width: 1280px) {
  .wrap {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 960px) {
  .wrap {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .wrap {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.inner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
}

@media screen and (max-width: 1280px) {
  .inner {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 960px) {
  .inner {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .inner {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.mb-sect {
  margin-bottom: calc(8px * 10);
}

@media screen and (max-width: 960px) {
  .mb-sect {
    margin-bottom: calc((8px * 10) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .mb-sect {
    margin-bottom: calc((8px * 10) * 0.5);
  }
}

.mr-x1 {
  margin-right: 8px;
}

@media screen and (max-width: 960px) {
  .mr-x1 {
    margin-right: 6px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x1 {
    margin-right: 4px;
  }
}

.mb-x1 {
  margin-bottom: 8px;
}

@media screen and (max-width: 960px) {
  .mb-x1 {
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x1 {
    margin-bottom: 4px;
  }
}

.ml-x1 {
  margin-left: 8px;
}

@media screen and (max-width: 960px) {
  .ml-x1 {
    margin-left: 6px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x1 {
    margin-left: 4px;
  }
}

.mr-x2 {
  margin-right: 16px;
}

@media screen and (max-width: 960px) {
  .mr-x2 {
    margin-right: 12px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x2 {
    margin-right: 8px;
  }
}

.mb-x2 {
  margin-bottom: 16px;
}

@media screen and (max-width: 960px) {
  .mb-x2 {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x2 {
    margin-bottom: 8px;
  }
}

.ml-x2 {
  margin-left: 16px;
}

@media screen and (max-width: 960px) {
  .ml-x2 {
    margin-left: 12px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x2 {
    margin-left: 8px;
  }
}

.mr-x3 {
  margin-right: 24px;
}

@media screen and (max-width: 960px) {
  .mr-x3 {
    margin-right: 18px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x3 {
    margin-right: 12px;
  }
}

.mb-x3 {
  margin-bottom: 24px;
}

@media screen and (max-width: 960px) {
  .mb-x3 {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x3 {
    margin-bottom: 12px;
  }
}

.ml-x3 {
  margin-left: 24px;
}

@media screen and (max-width: 960px) {
  .ml-x3 {
    margin-left: 18px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x3 {
    margin-left: 12px;
  }
}

.mr-x4 {
  margin-right: 32px;
}

@media screen and (max-width: 960px) {
  .mr-x4 {
    margin-right: 24px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x4 {
    margin-right: 16px;
  }
}

.mb-x4 {
  margin-bottom: 32px;
}

@media screen and (max-width: 960px) {
  .mb-x4 {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x4 {
    margin-bottom: 16px;
  }
}

.ml-x4 {
  margin-left: 32px;
}

@media screen and (max-width: 960px) {
  .ml-x4 {
    margin-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x4 {
    margin-left: 16px;
  }
}

.mr-x5 {
  margin-right: 40px;
}

@media screen and (max-width: 960px) {
  .mr-x5 {
    margin-right: 30px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x5 {
    margin-right: 20px;
  }
}

.mb-x5 {
  margin-bottom: 40px;
}

@media screen and (max-width: 960px) {
  .mb-x5 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x5 {
    margin-bottom: 20px;
  }
}

.ml-x5 {
  margin-left: 40px;
}

@media screen and (max-width: 960px) {
  .ml-x5 {
    margin-left: 30px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x5 {
    margin-left: 20px;
  }
}

.mr-x6 {
  margin-right: 48px;
}

@media screen and (max-width: 960px) {
  .mr-x6 {
    margin-right: 36px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x6 {
    margin-right: 24px;
  }
}

.mb-x6 {
  margin-bottom: 48px;
}

@media screen and (max-width: 960px) {
  .mb-x6 {
    margin-bottom: 36px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x6 {
    margin-bottom: 24px;
  }
}

.ml-x6 {
  margin-left: 48px;
}

@media screen and (max-width: 960px) {
  .ml-x6 {
    margin-left: 36px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x6 {
    margin-left: 24px;
  }
}

.mr-x7 {
  margin-right: 56px;
}

@media screen and (max-width: 960px) {
  .mr-x7 {
    margin-right: 42px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x7 {
    margin-right: 28px;
  }
}

.mb-x7 {
  margin-bottom: 56px;
}

@media screen and (max-width: 960px) {
  .mb-x7 {
    margin-bottom: 42px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x7 {
    margin-bottom: 28px;
  }
}

.ml-x7 {
  margin-left: 56px;
}

@media screen and (max-width: 960px) {
  .ml-x7 {
    margin-left: 42px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x7 {
    margin-left: 28px;
  }
}

.mr-x8 {
  margin-right: 64px;
}

@media screen and (max-width: 960px) {
  .mr-x8 {
    margin-right: 48px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x8 {
    margin-right: 32px;
  }
}

.mb-x8 {
  margin-bottom: 64px;
}

@media screen and (max-width: 960px) {
  .mb-x8 {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x8 {
    margin-bottom: 32px;
  }
}

.ml-x8 {
  margin-left: 64px;
}

@media screen and (max-width: 960px) {
  .ml-x8 {
    margin-left: 48px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x8 {
    margin-left: 32px;
  }
}

.mr-x9 {
  margin-right: 72px;
}

@media screen and (max-width: 960px) {
  .mr-x9 {
    margin-right: 54px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x9 {
    margin-right: 36px;
  }
}

.mb-x9 {
  margin-bottom: 72px;
}

@media screen and (max-width: 960px) {
  .mb-x9 {
    margin-bottom: 54px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x9 {
    margin-bottom: 36px;
  }
}

.ml-x9 {
  margin-left: 72px;
}

@media screen and (max-width: 960px) {
  .ml-x9 {
    margin-left: 54px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x9 {
    margin-left: 36px;
  }
}

.mr-x10 {
  margin-right: 80px;
}

@media screen and (max-width: 960px) {
  .mr-x10 {
    margin-right: 60px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x10 {
    margin-right: 40px;
  }
}

.mb-x10 {
  margin-bottom: 80px;
}

@media screen and (max-width: 960px) {
  .mb-x10 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x10 {
    margin-bottom: 40px;
  }
}

.ml-x10 {
  margin-left: 80px;
}

@media screen and (max-width: 960px) {
  .ml-x10 {
    margin-left: 60px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x10 {
    margin-left: 40px;
  }
}

.mr-x11 {
  margin-right: 88px;
}

@media screen and (max-width: 960px) {
  .mr-x11 {
    margin-right: 66px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x11 {
    margin-right: 44px;
  }
}

.mb-x11 {
  margin-bottom: 88px;
}

@media screen and (max-width: 960px) {
  .mb-x11 {
    margin-bottom: 66px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x11 {
    margin-bottom: 44px;
  }
}

.ml-x11 {
  margin-left: 88px;
}

@media screen and (max-width: 960px) {
  .ml-x11 {
    margin-left: 66px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x11 {
    margin-left: 44px;
  }
}

.mr-x12 {
  margin-right: 96px;
}

@media screen and (max-width: 960px) {
  .mr-x12 {
    margin-right: 72px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x12 {
    margin-right: 48px;
  }
}

.mb-x12 {
  margin-bottom: 96px;
}

@media screen and (max-width: 960px) {
  .mb-x12 {
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x12 {
    margin-bottom: 48px;
  }
}

.ml-x12 {
  margin-left: 96px;
}

@media screen and (max-width: 960px) {
  .ml-x12 {
    margin-left: 72px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x12 {
    margin-left: 48px;
  }
}

.mr-x13 {
  margin-right: 104px;
}

@media screen and (max-width: 960px) {
  .mr-x13 {
    margin-right: 78px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x13 {
    margin-right: 52px;
  }
}

.mb-x13 {
  margin-bottom: 104px;
}

@media screen and (max-width: 960px) {
  .mb-x13 {
    margin-bottom: 78px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x13 {
    margin-bottom: 52px;
  }
}

.ml-x13 {
  margin-left: 104px;
}

@media screen and (max-width: 960px) {
  .ml-x13 {
    margin-left: 78px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x13 {
    margin-left: 52px;
  }
}

.mr-x14 {
  margin-right: 112px;
}

@media screen and (max-width: 960px) {
  .mr-x14 {
    margin-right: 84px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x14 {
    margin-right: 56px;
  }
}

.mb-x14 {
  margin-bottom: 112px;
}

@media screen and (max-width: 960px) {
  .mb-x14 {
    margin-bottom: 84px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x14 {
    margin-bottom: 56px;
  }
}

.ml-x14 {
  margin-left: 112px;
}

@media screen and (max-width: 960px) {
  .ml-x14 {
    margin-left: 84px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x14 {
    margin-left: 56px;
  }
}

.mr-x15 {
  margin-right: 120px;
}

@media screen and (max-width: 960px) {
  .mr-x15 {
    margin-right: 90px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x15 {
    margin-right: 60px;
  }
}

.mb-x15 {
  margin-bottom: 120px;
}

@media screen and (max-width: 960px) {
  .mb-x15 {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x15 {
    margin-bottom: 60px;
  }
}

.ml-x15 {
  margin-left: 120px;
}

@media screen and (max-width: 960px) {
  .ml-x15 {
    margin-left: 90px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x15 {
    margin-left: 60px;
  }
}

.mr-x16 {
  margin-right: 128px;
}

@media screen and (max-width: 960px) {
  .mr-x16 {
    margin-right: 96px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x16 {
    margin-right: 64px;
  }
}

.mb-x16 {
  margin-bottom: 128px;
}

@media screen and (max-width: 960px) {
  .mb-x16 {
    margin-bottom: 96px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x16 {
    margin-bottom: 64px;
  }
}

.ml-x16 {
  margin-left: 128px;
}

@media screen and (max-width: 960px) {
  .ml-x16 {
    margin-left: 96px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x16 {
    margin-left: 64px;
  }
}

.mr-x17 {
  margin-right: 136px;
}

@media screen and (max-width: 960px) {
  .mr-x17 {
    margin-right: 102px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x17 {
    margin-right: 68px;
  }
}

.mb-x17 {
  margin-bottom: 136px;
}

@media screen and (max-width: 960px) {
  .mb-x17 {
    margin-bottom: 102px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x17 {
    margin-bottom: 68px;
  }
}

.ml-x17 {
  margin-left: 136px;
}

@media screen and (max-width: 960px) {
  .ml-x17 {
    margin-left: 102px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x17 {
    margin-left: 68px;
  }
}

.mr-x18 {
  margin-right: 144px;
}

@media screen and (max-width: 960px) {
  .mr-x18 {
    margin-right: 108px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x18 {
    margin-right: 72px;
  }
}

.mb-x18 {
  margin-bottom: 144px;
}

@media screen and (max-width: 960px) {
  .mb-x18 {
    margin-bottom: 108px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x18 {
    margin-bottom: 72px;
  }
}

.ml-x18 {
  margin-left: 144px;
}

@media screen and (max-width: 960px) {
  .ml-x18 {
    margin-left: 108px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x18 {
    margin-left: 72px;
  }
}

.mr-x19 {
  margin-right: 152px;
}

@media screen and (max-width: 960px) {
  .mr-x19 {
    margin-right: 114px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x19 {
    margin-right: 76px;
  }
}

.mb-x19 {
  margin-bottom: 152px;
}

@media screen and (max-width: 960px) {
  .mb-x19 {
    margin-bottom: 114px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x19 {
    margin-bottom: 76px;
  }
}

.ml-x19 {
  margin-left: 152px;
}

@media screen and (max-width: 960px) {
  .ml-x19 {
    margin-left: 114px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x19 {
    margin-left: 76px;
  }
}

.mr-x20 {
  margin-right: 160px;
}

@media screen and (max-width: 960px) {
  .mr-x20 {
    margin-right: 120px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x20 {
    margin-right: 80px;
  }
}

.mb-x20 {
  margin-bottom: 160px;
}

@media screen and (max-width: 960px) {
  .mb-x20 {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x20 {
    margin-bottom: 80px;
  }
}

.ml-x20 {
  margin-left: 160px;
}

@media screen and (max-width: 960px) {
  .ml-x20 {
    margin-left: 120px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x20 {
    margin-left: 80px;
  }
}

.pt-x1 {
  padding-top: 8px;
}

@media screen and (max-width: 960px) {
  .pt-x1 {
    padding-top: 6px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x1 {
    padding-top: 4px;
  }
}

.pr-x1 {
  padding-right: 8px;
}

@media screen and (max-width: 960px) {
  .pr-x1 {
    padding-right: 6px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x1 {
    padding-right: 4px;
  }
}

.pb-x1 {
  padding-bottom: 8px;
}

@media screen and (max-width: 960px) {
  .pb-x1 {
    padding-bottom: 6px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x1 {
    padding-bottom: 4px;
  }
}

.pl-x1 {
  padding-left: 8px;
}

@media screen and (max-width: 960px) {
  .pl-x1 {
    padding-left: 6px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x1 {
    padding-left: 4px;
  }
}

.pt-x2 {
  padding-top: 16px;
}

@media screen and (max-width: 960px) {
  .pt-x2 {
    padding-top: 12px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x2 {
    padding-top: 8px;
  }
}

.pr-x2 {
  padding-right: 16px;
}

@media screen and (max-width: 960px) {
  .pr-x2 {
    padding-right: 12px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x2 {
    padding-right: 8px;
  }
}

.pb-x2 {
  padding-bottom: 16px;
}

@media screen and (max-width: 960px) {
  .pb-x2 {
    padding-bottom: 12px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x2 {
    padding-bottom: 8px;
  }
}

.pl-x2 {
  padding-left: 16px;
}

@media screen and (max-width: 960px) {
  .pl-x2 {
    padding-left: 12px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x2 {
    padding-left: 8px;
  }
}

.pt-x3 {
  padding-top: 24px;
}

@media screen and (max-width: 960px) {
  .pt-x3 {
    padding-top: 18px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x3 {
    padding-top: 12px;
  }
}

.pr-x3 {
  padding-right: 24px;
}

@media screen and (max-width: 960px) {
  .pr-x3 {
    padding-right: 18px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x3 {
    padding-right: 12px;
  }
}

.pb-x3 {
  padding-bottom: 24px;
}

@media screen and (max-width: 960px) {
  .pb-x3 {
    padding-bottom: 18px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x3 {
    padding-bottom: 12px;
  }
}

.pl-x3 {
  padding-left: 24px;
}

@media screen and (max-width: 960px) {
  .pl-x3 {
    padding-left: 18px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x3 {
    padding-left: 12px;
  }
}

.pt-x4 {
  padding-top: 32px;
}

@media screen and (max-width: 960px) {
  .pt-x4 {
    padding-top: 24px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x4 {
    padding-top: 16px;
  }
}

.pr-x4 {
  padding-right: 32px;
}

@media screen and (max-width: 960px) {
  .pr-x4 {
    padding-right: 24px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x4 {
    padding-right: 16px;
  }
}

.pb-x4 {
  padding-bottom: 32px;
}

@media screen and (max-width: 960px) {
  .pb-x4 {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x4 {
    padding-bottom: 16px;
  }
}

.pl-x4 {
  padding-left: 32px;
}

@media screen and (max-width: 960px) {
  .pl-x4 {
    padding-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x4 {
    padding-left: 16px;
  }
}

.pt-x5 {
  padding-top: 40px;
}

@media screen and (max-width: 960px) {
  .pt-x5 {
    padding-top: 30px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x5 {
    padding-top: 20px;
  }
}

.pr-x5 {
  padding-right: 40px;
}

@media screen and (max-width: 960px) {
  .pr-x5 {
    padding-right: 30px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x5 {
    padding-right: 20px;
  }
}

.pb-x5 {
  padding-bottom: 40px;
}

@media screen and (max-width: 960px) {
  .pb-x5 {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x5 {
    padding-bottom: 20px;
  }
}

.pl-x5 {
  padding-left: 40px;
}

@media screen and (max-width: 960px) {
  .pl-x5 {
    padding-left: 30px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x5 {
    padding-left: 20px;
  }
}

.pt-x6 {
  padding-top: 48px;
}

@media screen and (max-width: 960px) {
  .pt-x6 {
    padding-top: 36px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x6 {
    padding-top: 24px;
  }
}

.pr-x6 {
  padding-right: 48px;
}

@media screen and (max-width: 960px) {
  .pr-x6 {
    padding-right: 36px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x6 {
    padding-right: 24px;
  }
}

.pb-x6 {
  padding-bottom: 48px;
}

@media screen and (max-width: 960px) {
  .pb-x6 {
    padding-bottom: 36px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x6 {
    padding-bottom: 24px;
  }
}

.pl-x6 {
  padding-left: 48px;
}

@media screen and (max-width: 960px) {
  .pl-x6 {
    padding-left: 36px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x6 {
    padding-left: 24px;
  }
}

.pt-x7 {
  padding-top: 56px;
}

@media screen and (max-width: 960px) {
  .pt-x7 {
    padding-top: 42px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x7 {
    padding-top: 28px;
  }
}

.pr-x7 {
  padding-right: 56px;
}

@media screen and (max-width: 960px) {
  .pr-x7 {
    padding-right: 42px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x7 {
    padding-right: 28px;
  }
}

.pb-x7 {
  padding-bottom: 56px;
}

@media screen and (max-width: 960px) {
  .pb-x7 {
    padding-bottom: 42px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x7 {
    padding-bottom: 28px;
  }
}

.pl-x7 {
  padding-left: 56px;
}

@media screen and (max-width: 960px) {
  .pl-x7 {
    padding-left: 42px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x7 {
    padding-left: 28px;
  }
}

.pt-x8 {
  padding-top: 64px;
}

@media screen and (max-width: 960px) {
  .pt-x8 {
    padding-top: 48px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x8 {
    padding-top: 32px;
  }
}

.pr-x8 {
  padding-right: 64px;
}

@media screen and (max-width: 960px) {
  .pr-x8 {
    padding-right: 48px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x8 {
    padding-right: 32px;
  }
}

.pb-x8 {
  padding-bottom: 64px;
}

@media screen and (max-width: 960px) {
  .pb-x8 {
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x8 {
    padding-bottom: 32px;
  }
}

.pl-x8 {
  padding-left: 64px;
}

@media screen and (max-width: 960px) {
  .pl-x8 {
    padding-left: 48px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x8 {
    padding-left: 32px;
  }
}

.pt-x9 {
  padding-top: 72px;
}

@media screen and (max-width: 960px) {
  .pt-x9 {
    padding-top: 54px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x9 {
    padding-top: 36px;
  }
}

.pr-x9 {
  padding-right: 72px;
}

@media screen and (max-width: 960px) {
  .pr-x9 {
    padding-right: 54px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x9 {
    padding-right: 36px;
  }
}

.pb-x9 {
  padding-bottom: 72px;
}

@media screen and (max-width: 960px) {
  .pb-x9 {
    padding-bottom: 54px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x9 {
    padding-bottom: 36px;
  }
}

.pl-x9 {
  padding-left: 72px;
}

@media screen and (max-width: 960px) {
  .pl-x9 {
    padding-left: 54px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x9 {
    padding-left: 36px;
  }
}

.pt-x10 {
  padding-top: 80px;
}

@media screen and (max-width: 960px) {
  .pt-x10 {
    padding-top: 60px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x10 {
    padding-top: 40px;
  }
}

.pr-x10 {
  padding-right: 80px;
}

@media screen and (max-width: 960px) {
  .pr-x10 {
    padding-right: 60px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x10 {
    padding-right: 40px;
  }
}

.pb-x10 {
  padding-bottom: 80px;
}

@media screen and (max-width: 960px) {
  .pb-x10 {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x10 {
    padding-bottom: 40px;
  }
}

.pl-x10 {
  padding-left: 80px;
}

@media screen and (max-width: 960px) {
  .pl-x10 {
    padding-left: 60px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x10 {
    padding-left: 40px;
  }
}

.pt-x11 {
  padding-top: 88px;
}

@media screen and (max-width: 960px) {
  .pt-x11 {
    padding-top: 66px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x11 {
    padding-top: 44px;
  }
}

.pr-x11 {
  padding-right: 88px;
}

@media screen and (max-width: 960px) {
  .pr-x11 {
    padding-right: 66px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x11 {
    padding-right: 44px;
  }
}

.pb-x11 {
  padding-bottom: 88px;
}

@media screen and (max-width: 960px) {
  .pb-x11 {
    padding-bottom: 66px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x11 {
    padding-bottom: 44px;
  }
}

.pl-x11 {
  padding-left: 88px;
}

@media screen and (max-width: 960px) {
  .pl-x11 {
    padding-left: 66px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x11 {
    padding-left: 44px;
  }
}

.pt-x12 {
  padding-top: 96px;
}

@media screen and (max-width: 960px) {
  .pt-x12 {
    padding-top: 72px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x12 {
    padding-top: 48px;
  }
}

.pr-x12 {
  padding-right: 96px;
}

@media screen and (max-width: 960px) {
  .pr-x12 {
    padding-right: 72px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x12 {
    padding-right: 48px;
  }
}

.pb-x12 {
  padding-bottom: 96px;
}

@media screen and (max-width: 960px) {
  .pb-x12 {
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x12 {
    padding-bottom: 48px;
  }
}

.pl-x12 {
  padding-left: 96px;
}

@media screen and (max-width: 960px) {
  .pl-x12 {
    padding-left: 72px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x12 {
    padding-left: 48px;
  }
}

.pt-x13 {
  padding-top: 104px;
}

@media screen and (max-width: 960px) {
  .pt-x13 {
    padding-top: 78px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x13 {
    padding-top: 52px;
  }
}

.pr-x13 {
  padding-right: 104px;
}

@media screen and (max-width: 960px) {
  .pr-x13 {
    padding-right: 78px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x13 {
    padding-right: 52px;
  }
}

.pb-x13 {
  padding-bottom: 104px;
}

@media screen and (max-width: 960px) {
  .pb-x13 {
    padding-bottom: 78px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x13 {
    padding-bottom: 52px;
  }
}

.pl-x13 {
  padding-left: 104px;
}

@media screen and (max-width: 960px) {
  .pl-x13 {
    padding-left: 78px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x13 {
    padding-left: 52px;
  }
}

.pt-x14 {
  padding-top: 112px;
}

@media screen and (max-width: 960px) {
  .pt-x14 {
    padding-top: 84px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x14 {
    padding-top: 56px;
  }
}

.pr-x14 {
  padding-right: 112px;
}

@media screen and (max-width: 960px) {
  .pr-x14 {
    padding-right: 84px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x14 {
    padding-right: 56px;
  }
}

.pb-x14 {
  padding-bottom: 112px;
}

@media screen and (max-width: 960px) {
  .pb-x14 {
    padding-bottom: 84px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x14 {
    padding-bottom: 56px;
  }
}

.pl-x14 {
  padding-left: 112px;
}

@media screen and (max-width: 960px) {
  .pl-x14 {
    padding-left: 84px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x14 {
    padding-left: 56px;
  }
}

.pt-x15 {
  padding-top: 120px;
}

@media screen and (max-width: 960px) {
  .pt-x15 {
    padding-top: 90px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x15 {
    padding-top: 60px;
  }
}

.pr-x15 {
  padding-right: 120px;
}

@media screen and (max-width: 960px) {
  .pr-x15 {
    padding-right: 90px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x15 {
    padding-right: 60px;
  }
}

.pb-x15 {
  padding-bottom: 120px;
}

@media screen and (max-width: 960px) {
  .pb-x15 {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x15 {
    padding-bottom: 60px;
  }
}

.pl-x15 {
  padding-left: 120px;
}

@media screen and (max-width: 960px) {
  .pl-x15 {
    padding-left: 90px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x15 {
    padding-left: 60px;
  }
}

.pt-x16 {
  padding-top: 128px;
}

@media screen and (max-width: 960px) {
  .pt-x16 {
    padding-top: 96px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x16 {
    padding-top: 64px;
  }
}

.pr-x16 {
  padding-right: 128px;
}

@media screen and (max-width: 960px) {
  .pr-x16 {
    padding-right: 96px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x16 {
    padding-right: 64px;
  }
}

.pb-x16 {
  padding-bottom: 128px;
}

@media screen and (max-width: 960px) {
  .pb-x16 {
    padding-bottom: 96px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x16 {
    padding-bottom: 64px;
  }
}

.pl-x16 {
  padding-left: 128px;
}

@media screen and (max-width: 960px) {
  .pl-x16 {
    padding-left: 96px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x16 {
    padding-left: 64px;
  }
}

.pt-x17 {
  padding-top: 136px;
}

@media screen and (max-width: 960px) {
  .pt-x17 {
    padding-top: 102px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x17 {
    padding-top: 68px;
  }
}

.pr-x17 {
  padding-right: 136px;
}

@media screen and (max-width: 960px) {
  .pr-x17 {
    padding-right: 102px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x17 {
    padding-right: 68px;
  }
}

.pb-x17 {
  padding-bottom: 136px;
}

@media screen and (max-width: 960px) {
  .pb-x17 {
    padding-bottom: 102px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x17 {
    padding-bottom: 68px;
  }
}

.pl-x17 {
  padding-left: 136px;
}

@media screen and (max-width: 960px) {
  .pl-x17 {
    padding-left: 102px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x17 {
    padding-left: 68px;
  }
}

.pt-x18 {
  padding-top: 144px;
}

@media screen and (max-width: 960px) {
  .pt-x18 {
    padding-top: 108px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x18 {
    padding-top: 72px;
  }
}

.pr-x18 {
  padding-right: 144px;
}

@media screen and (max-width: 960px) {
  .pr-x18 {
    padding-right: 108px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x18 {
    padding-right: 72px;
  }
}

.pb-x18 {
  padding-bottom: 144px;
}

@media screen and (max-width: 960px) {
  .pb-x18 {
    padding-bottom: 108px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x18 {
    padding-bottom: 72px;
  }
}

.pl-x18 {
  padding-left: 144px;
}

@media screen and (max-width: 960px) {
  .pl-x18 {
    padding-left: 108px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x18 {
    padding-left: 72px;
  }
}

.pt-x19 {
  padding-top: 152px;
}

@media screen and (max-width: 960px) {
  .pt-x19 {
    padding-top: 114px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x19 {
    padding-top: 76px;
  }
}

.pr-x19 {
  padding-right: 152px;
}

@media screen and (max-width: 960px) {
  .pr-x19 {
    padding-right: 114px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x19 {
    padding-right: 76px;
  }
}

.pb-x19 {
  padding-bottom: 152px;
}

@media screen and (max-width: 960px) {
  .pb-x19 {
    padding-bottom: 114px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x19 {
    padding-bottom: 76px;
  }
}

.pl-x19 {
  padding-left: 152px;
}

@media screen and (max-width: 960px) {
  .pl-x19 {
    padding-left: 114px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x19 {
    padding-left: 76px;
  }
}

.pt-x20 {
  padding-top: 160px;
}

@media screen and (max-width: 960px) {
  .pt-x20 {
    padding-top: 120px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x20 {
    padding-top: 80px;
  }
}

.pr-x20 {
  padding-right: 160px;
}

@media screen and (max-width: 960px) {
  .pr-x20 {
    padding-right: 120px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x20 {
    padding-right: 80px;
  }
}

.pb-x20 {
  padding-bottom: 160px;
}

@media screen and (max-width: 960px) {
  .pb-x20 {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x20 {
    padding-bottom: 80px;
  }
}

.pl-x20 {
  padding-left: 160px;
}

@media screen and (max-width: 960px) {
  .pl-x20 {
    padding-left: 120px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x20 {
    padding-left: 80px;
  }
}

[anime] {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

[anime].is-animated {
  opacity: 1;
}

[anime*="scale"].is-animated {
  animation-name: scale;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

[anime*="scale-top"].is-animated {
  animation-name: scale;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  transform-origin: 50% 0%;
}

[anime*="scale-right"].is-animated {
  animation-name: scale;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  transform-origin: 100% 50%;
}

[anime*="scale-bottom"].is-animated {
  animation-name: scale;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  transform-origin: 50% 100%;
}

[anime*="scale-left"].is-animated {
  animation-name: scale;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  transform-origin: 0% 50%;
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

[anime*="fade-front"].is-animated {
  animation-name: fade-front;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes fade-front {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

[anime*="fade-back"].is-animated {
  animation-name: fade-back;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes fade-back {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

[anime*="slide-top"].is-animated {
  animation-name: slide-top;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes slide-top {
  0% {
    transform: translateY(-200px);
  }
  100% {
    transform: translateY(0);
  }
}

[anime*="slide-right"].is-animated {
  animation-name: slide-right;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes slide-right {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}

[anime*="slide-bottom"].is-animated {
  animation-name: slide-bottom;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes slide-bottom {
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateX(0);
  }
}

[anime*="slide-left"].is-animated {
  animation-name: slide-left;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes slide-left {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(0);
  }
}

[anime*="flip-top"].is-animated {
  animation-name: flip-top;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes flip-top {
  0% {
    transform: perspective(1000px) rotateX(80deg);
  }
  100% {
    transform: perspective(1000px) rotateX(0);
  }
}

[anime*="flip-right"].is-animated {
  animation-name: flip-right;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes flip-right {
  0% {
    transform: perspective(1000px) rotateY(80deg);
  }
  100% {
    transform: perspective(1000px) rotateY(0);
  }
}

[anime*="flip-bottom"].is-animated {
  animation-name: flip-bottom;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes flip-bottom {
  0% {
    transform: perspective(1000px) rotateX(-80deg);
  }
  100% {
    transform: perspective(1000px) rotateX(0);
  }
}

[anime*="flip-left"].is-animated {
  animation-name: flip-left;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes flip-left {
  0% {
    transform: perspective(1000px) rotateY(-80deg);
  }
  100% {
    transform: perspective(1000px) rotateY(0);
  }
}

[anime*="bound-top"].is-animated {
  animation-name: bound-top;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes bound-top {
  0% {
    transform: translateY(-48px);
    animation-timing-function: ease-in;
  }
  40% {
    transform: translateY(-24px);
    animation-timing-function: ease-in;
  }
  64% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }
  94% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }
  25%,
  56%,
  74%,
  88%,
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
}

[anime*="bound-right"].is-animated {
  animation-name: bound-right;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes bound-right {
  0% {
    transform: translateX(48px);
    animation-timing-function: ease-in;
  }
  40% {
    transform: translateX(24px);
    animation-timing-function: ease-in;
  }
  64% {
    transform: translateX(12px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateX(6px);
    animation-timing-function: ease-in;
  }
  94% {
    transform: translateX(4px);
    animation-timing-function: ease-in;
  }
  25%,
  56%,
  74%,
  88%,
  100% {
    transform: translateX(0px);
    animation-timing-function: ease-out;
  }
}

[anime*="bound-bottom"].is-animated {
  animation-name: bound-bottom;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes bound-bottom {
  0% {
    transform: translateY(48px);
    animation-timing-function: ease-in;
  }
  40% {
    transform: translateY(24px);
    animation-timing-function: ease-in;
  }
  64% {
    transform: translateY(12px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(6px);
    animation-timing-function: ease-in;
  }
  94% {
    transform: translateY(4px);
    animation-timing-function: ease-in;
  }
  25%,
  56%,
  74%,
  88%,
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
}

[anime*="bound-left"].is-animated {
  animation-name: bound-left;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes bound-left {
  0% {
    transform: translateX(-48px);
    animation-timing-function: ease-in;
  }
  40% {
    transform: translateX(-24px);
    animation-timing-function: ease-in;
  }
  64% {
    transform: translateX(-12px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateX(-6px);
    animation-timing-function: ease-in;
  }
  94% {
    transform: translateX(-4px);
    animation-timing-function: ease-in;
  }
  25%,
  56%,
  74%,
  88%,
  100% {
    transform: translateX(0px);
    animation-timing-function: ease-out;
  }
}

[anime*="focus"].is-animated {
  animation-name: focus;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes focus {
  0% {
    filter: blur(12px);
  }
  100% {
    filter: blur(0px);
  }
}

[anime*="txt-grow"].is-animated {
  animation-name: txt-grow;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes txt-grow {
  0% {
    letter-spacing: -0.8em;
  }
  100% {
    letter-spacing: normal;
  }
}

[anime*="txt-shrink"].is-animated {
  animation-name: txt-shrink;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes txt-shrink {
  0% {
    letter-spacing: 0.8em;
  }
  100% {
    letter-spacing: normal;
  }
}

[anime*="roll"] {
  transform: rotateZ(0);
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}

[anime*="roll"].is-animated {
  transform: rotateZ(360deg);
}

.header {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 40px 0;
  height: 110px;
  z-index: 9999;
  transition: 0.3s ease-out;
}

@media screen and (max-width: 1280px) {
  .header {
    padding: 24px 32px 0;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 24px 24px 0;
  }
}

@media screen and (max-width: 960px) {
  .header {
    padding: 16px 24px 0;
    height: 72px;
  }
}

@media screen and (max-width: 520px) {
  .header {
    padding: 12px 12px 0;
    height: 56px;
  }
}

.header .header-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 520px) {
  .header .header-wrap {
    align-items: flex-start;
  }
}

.header .header-logo {
  padding-top: 3px;
  width: calc( 100% - 648px);
  max-width: 480px;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .header .header-logo {
    width: calc( 100% - 624px);
  }
}

@media screen and (max-width: 960px) {
  .header .header-logo {
    width: 100%;
    max-width: 280px;
  }
}

@media screen and (max-width: 520px) {
  .header .header-logo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc( 100% - 152px);
    max-width: 220px;
  }
}

.header .header-logo a {
  position: relative;
  display: block;
  width: 100%;
}

.header .header-logo a .ico-logo {
  margin-bottom: 4px;
  aspect-ratio: 4/.35;
  fill: #ffffff;
}

@media screen and (max-width: 768px) {
  .header .header-logo a .ico-logo {
    margin-bottom: 0;
  }
}

.header .header-logo a .ico-logo-en {
  aspect-ratio: 3/.16;
  fill: #ffffff;
  width: 71%;
  vertical-align: top;
}

.header .header-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 620px;
}

@media screen and (max-width: 1280px) {
  .header .header-menu {
    width: 616px;
  }
}

@media screen and (max-width: 1024px) {
  .header .header-menu {
    width: 600px;
  }
}

@media screen and (max-width: 960px) {
  .header .header-menu {
    width: auto;
    padding-right: 64px;
  }
}

@media screen and (max-width: 520px) {
  .header .header-menu {
    padding-right: 40px;
  }
}

.header .header-menu .header-lang .language-box {
  position: relative;
}

.header .header-menu .header-lang .language-box .language-box-switch {
  display: none;
}

.header .header-menu .header-lang .language-box .language-box-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 32px;
  border: solid 1px #ffffff;
  border-radius: 4px;
  text-align: center;
  font-family: "Jost", sans-serif;
  line-height: 1;
  color: #ffffff;
  font-size: 1.3rem;
  cursor: pointer;
  transition: 0.3s ease-out;
}

@media screen and (max-width: 520px) {
  .header .header-menu .header-lang .language-box .language-box-btn {
    width: 72px;
    height: 28px;
  }
}

.header .header-menu .header-lang .language-box .language-box-list {
  position: absolute;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-out;
}

.header .header-menu .header-lang .language-box .language-box-list__item {
  overflow: auto;
}

.header .header-menu .header-lang .language-box .language-box-list__item a {
  display: block;
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #ffffff;
  font-size: 1rem;
}

.header .header-menu .header-lang .language-box .language-box-list__item a:hover {
  background: #eeeeee;
}

.header .header-menu .header-lang .language-box:hover .language-box-btn, .header .header-menu .header-lang .language-box:focus-within .language-box-btn {
  border-radius: 4px 4px 0 0;
  background: #ffffff;
  color: #4977ad;
}

.header .header-menu .header-lang .language-box:hover .language-box-list, .header .header-menu .header-lang .language-box:focus-within .language-box-list {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .header .header-menu .header-lang .language-box:hover .language-box-list, .header .header-menu .header-lang .language-box:focus-within .language-box-list {
    visibility: hidden;
    opacity: 0;
  }
}

.header .header-menu .header-lang .language-box .language-box-switch:checked ~ .language-box-list {
  visibility: visible;
  opacity: 1;
}

.header .header-menu .global-nav {
  padding-right: 48px;
  width: calc( 100% - 98px);
}

@media screen and (max-width: 1280px) {
  .header .header-menu .global-nav {
    padding-right: 44px;
  }
}

@media screen and (max-width: 960px) {
  .header .header-menu .global-nav {
    display: none;
  }
}

.header .header-menu .global-nav .global-nav-list {
  display: flex;
  gap: 24px;
}

@media screen and (max-width: 1024px) {
  .header .header-menu .global-nav .global-nav-list {
    gap: 20px;
  }
}

.header .header-menu .global-nav .global-nav-list__item {
  position: relative;
}

.header .header-menu .global-nav .global-nav-list__item:not(:last-of-type)::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  right: -12px;
  width: 1px;
  height: 16px;
  background: #ffffff;
}

@media screen and (max-width: 1024px) {
  .header .header-menu .global-nav .global-nav-list__item:not(:last-of-type)::after {
    right: -10px;
  }
}

.header .header-menu .global-nav .global-nav-list__item a {
  display: block;
  color: #ffffff;
  font-size: 1.3rem;
}

.header .header-menu .global-nav .global-nav-list__item a:hover {
  transform: translateY(2px);
}

.header .header-menu .header-search {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
}

@media screen and (max-width: 960px) {
  .header .header-menu .header-search {
    display: none;
  }
}

.header.float-header {
  padding: 0 40px;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 5px 12px rgba(35, 31, 32, 0.06);
}

@media screen and (max-width: 1280px) {
  .header.float-header {
    padding: 0 32px;
  }
}

@media screen and (max-width: 1024px) {
  .header.float-header {
    padding: 0 24px;
  }
}

@media screen and (max-width: 520px) {
  .header.float-header {
    padding: 0 12px;
  }
}

.header.float-header .header-wrap {
  align-items: center;
}

.header.float-header .header-logo {
  padding-top: 13px;
}

@media screen and (max-width: 768px) {
  .header.float-header .header-logo {
    padding-top: 10px;
  }
}

.header.float-header .header-logo a .ico-logo {
  margin-bottom: 0;
  max-width: 400px;
  max-height: 35px;
  fill: #4977ad;
}

@media screen and (max-width: 1024px) {
  .header.float-header .header-logo a .ico-logo {
    max-width: 297px;
    max-height: 26px;
  }
}

.header.float-header .header-logo a .ico-logo-en {
  max-width: 280px;
  max-height: 15px;
  fill: #aaa;
}

@media screen and (max-width: 1024px) {
  .header.float-header .header-logo a .ico-logo-en {
    max-width: 206px;
    max-height: 11px;
  }
}

.header.float-header .header-menu {
  gap: 8px 12px;
}

.header.float-header .header-menu .header-lang .language-box .language-box-btn {
  border-color: #4977ad;
  color: #4977ad;
}

.header.float-header .header-menu .global-nav .global-nav-list__item:not(:last-of-type)::after {
  background: #4977ad;
}

.header.float-header .header-menu .global-nav .global-nav-list__item a {
  color: #4977ad;
}

.header.float-header .header-menu .header-search .search-box .search-box-inner dt input {
  background: #eeeeee;
  height: 36px;
}

.search-box {
  width: 100%;
}

.search-box .search-box-inner {
  position: relative;
}

.search-box .search-box-inner dt input {
  padding: 0 16px;
  width: 100%;
  height: 40px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.85);
  font-family: "メイリオ", "Meiryo", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif;
  line-height: 40px;
}

.search-box .search-box-inner dd button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 16px;
  height: 16px;
}

.search-box .search-box-inner dd button svg {
  fill: #1aa9db;
  width: 16px;
  height: 16px;
}

.drawer {
  position: relative;
  top: 0;
  right: 0;
  z-index: 9999;
}

.drawer .drawer-switch {
  display: none;
}

.drawer-hamburger {
  display: block;
  position: fixed;
  top: 29px;
  right: 48px;
  width: 28px;
  height: 22px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 9999;
}

@media screen and (max-width: 1280px) {
  .drawer-hamburger {
    right: 32px;
  }
}

@media screen and (max-width: 960px) {
  .drawer-hamburger {
    top: 32px;
  }
}

@media screen and (max-width: 520px) {
  .drawer-hamburger {
    top: 16px;
    right: 12px;
  }
}

.drawer-hamburger::before {
  position: absolute;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 22px;
  height: 2px;
  background: #ffffff;
}

.drawer-hamburger span {
  display: block;
  position: relative;
  top: 0;
  width: 28px;
  height: 22px;
  margin: 0 auto;
}

.drawer-hamburger span::before, .drawer-hamburger span::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  height: 2px;
  background: #ffffff;
  transition: 0.3s ease-in-out;
}

.drawer-hamburger span::before {
  top: 0;
  width: 28px;
}

.drawer-hamburger span::after {
  bottom: 0;
  width: 16px;
}

.drawer-hamburger.--active::before {
  opacity: 0;
}

.drawer-hamburger.--active::after {
  opacity: 1;
}

.drawer-hamburger.--active span::before {
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
}

.drawer-hamburger.--active span::after {
  top: 50%;
  transform: rotate(-45deg) translateY(-50%);
  width: 28px;
}

.drawer-overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
  height: 80px;
  background: #4977ad;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .drawer-overlay {
    height: 92px;
  }
}

@media screen and (max-width: 520px) {
  .drawer-overlay {
    height: 56px;
  }
}

.drawer-overlay.--active {
  visibility: visible;
  opacity: 1;
}

.drawer-container {
  overflow: auto;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 9998;
  width: 100%;
  height: calc( 100% - 80px);
  padding: 0 40px 40px;
  background: #4977ad;
  transform: translateX(-100%);
  transition: 0.3s ease-in-out;
  visibility: hidden;
}

@media screen and (max-width: 1280px) {
  .drawer-container {
    padding: 0 32px 32px;
  }
}

@media screen and (max-width: 960px) {
  .drawer-container {
    top: 92px;
    height: calc( 100% - 92px);
    padding: 0 12px 12px;
  }
}

@media screen and (max-width: 520px) {
  .drawer-container {
    top: 56px;
    height: calc( 100% - 56px);
    padding: 0 8px 8px;
  }
}

.drawer-container.--active {
  transform: translateX(0);
  visibility: initial;
}

.drawer .drawer-search {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(8px * 5);
  max-width: 640px;
}

@media screen and (max-width: 960px) {
  .drawer .drawer-search {
    margin-bottom: calc((8px * 5) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .drawer .drawer-search {
    margin-bottom: calc((8px * 5) * 0.5);
  }
}

.drawer .drawer-search .search-box .search-box-inner dt input {
  height: 48px;
}

@media screen and (max-width: 768px) {
  .drawer .drawer-search .search-box .search-box-inner dt input {
    height: 40px;
  }
}

.drawer-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  z-index: 9997;
}

@media screen and (max-width: 1280px) {
  .drawer-content {
    gap: 16px;
  }
}

@media screen and (max-width: 960px) {
  .drawer-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 520px) {
  .drawer-content {
    gap: 8px;
  }
}

.drawer-content__item {
  padding: 16px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
}

@media screen and (max-width: 1024px) {
  .drawer-content__item {
    padding: 14px;
  }
}

@media screen and (max-width: 520px) {
  .drawer-content__item {
    padding: 14px 10px;
  }
}

.drawer-content__item .drawer-menu-ttl {
  position: relative;
  margin-bottom: 9px;
  padding-bottom: 4px;
  font-weight: bold;
  color: #4977ad;
}

@media screen and (max-width: 960px) {
  .drawer-content__item .drawer-menu-ttl {
    margin-bottom: 16px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .drawer-content__item .drawer-menu-ttl {
    font-size: 1.3rem;
  }
}

.drawer-content__item .drawer-menu-ttl::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #4977ad 40px, #eeeeee 41px);
}

.drawer-content__item .drawer-menu-wrap {
  display: grid;
  gap: 32px;
}

@media screen and (max-width: 1280px) {
  .drawer-content__item .drawer-menu-wrap {
    gap: 24px;
  }
}

@media screen and (max-width: 960px) {
  .drawer-content__item .drawer-menu-wrap {
    gap: 16px;
  }
}

@media screen and (max-width: 520px) {
  .drawer-content__item .drawer-menu-wrap {
    gap: 16px 10px;
  }
}

.drawer-content__item .drawer-menu__item a {
  position: relative;
  display: block;
  padding-left: 12px;
  font-size: 1.3rem;
  line-height: 1.2;
}

.drawer-content__item .drawer-menu__item a::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 0;
  transform: rotateZ(45deg);
  width: 5px;
  height: 5px;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #4977ad #4977ad transparent transparent;
}

.drawer-content__item .drawer-menu__item a:hover {
  transform: translateX(2px);
  opacity: .8;
}

.drawer-content__item .drawer-menu__item:not(:last-child) {
  margin-bottom: 10px;
}

@media screen and (max-width: 960px) {
  .drawer-content__item .drawer-menu__item:not(:last-child) {
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 520px) {
  .drawer-content__item .drawer-menu__item:not(:last-child) {
    margin-bottom: 12px;
  }
}

.drawer-content__item:nth-child(3) {
  grid-column: 3 / 5;
}

@media screen and (max-width: 960px) {
  .drawer-content__item:nth-child(3) {
    grid-column: 1 / 3;
  }
}

.drawer-content__item:nth-child(3) .drawer-menu-wrap {
  grid-template-columns: repeat(2, 1fr);
}

.drawer-content__item:nth-child(4) {
  grid-column: 1 / 5;
}

@media screen and (max-width: 960px) {
  .drawer-content__item:nth-child(4) {
    grid-column: 1 / 3;
  }
}

.drawer-content__item:nth-child(4) .drawer-menu-wrap {
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 960px) {
  .drawer-content__item:nth-child(4) .drawer-menu-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

.drawer-content__item:nth-child(9) {
  grid-column: 1 / 5;
}

@media screen and (max-width: 960px) {
  .drawer-content__item:nth-child(9) {
    grid-column: 1 / 3;
  }
}

.drawer-content__item:nth-child(9) .drawer-menu-wrap {
  grid-template-columns: repeat(7, 1fr);
}

@media screen and (max-width: 960px) {
  .drawer-content__item:nth-child(9) .drawer-menu-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 520px) {
  .drawer-content__item:nth-child(9) .drawer-menu-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

.drawer-content__item:nth-child(10) {
  grid-column: 1 / 5;
}

@media screen and (max-width: 960px) {
  .drawer-content__item:nth-child(10) {
    grid-column: 1 / 3;
  }
}

.drawer-content__item:nth-child(10) .drawer-menu-wrap {
  grid-template-columns: repeat(5, 1fr);
}

@media screen and (max-width: 960px) {
  .drawer-content__item:nth-child(10) .drawer-menu-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 520px) {
  .drawer-content__item:nth-child(10) .drawer-menu-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

.drawer.float-drawer .drawer-hamburger {
  top: 20px;
}

@media screen and (max-width: 960px) {
  .drawer.float-drawer .drawer-hamburger {
    top: 24px;
  }
}

@media screen and (max-width: 520px) {
  .drawer.float-drawer .drawer-hamburger {
    top: 17px;
  }
}

.drawer.float-drawer .drawer-hamburger::before {
  background: #4977ad;
}

.drawer.float-drawer .drawer-hamburger span::before, .drawer.float-drawer .drawer-hamburger span::after {
  background: #4977ad;
}

.drawer.float-drawer .drawer-hamburger.--active {
  top: 29px;
}

@media screen and (max-width: 520px) {
  .drawer.float-drawer .drawer-hamburger.--active {
    top: 16px;
  }
}

.drawer.float-drawer .drawer-hamburger.--active span::before, .drawer.float-drawer .drawer-hamburger.--active span::after {
  background: #ffffff;
}

.footer {
  position: relative;
}

.footer .footer-btn-unit {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 32px 24px 48px;
}

@media screen and (max-width: 960px) {
  .footer .footer-btn-unit {
    gap: 16px;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer-btn-unit {
    grid-template-columns: 1fr;
    max-width: 640px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 520px) {
  .footer .footer-btn-unit {
    gap: 10px;
  }
}

.footer .footer-btn-unit__item a {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px 0 40px 40px;
  background: #f3f6e0;
  border-radius: 4px;
  box-shadow: 5px 5px 12px rgba(21, 56, 117, 0.06);
}

@media screen and (max-width: 1280px) {
  .footer .footer-btn-unit__item a {
    padding: 32px 16px;
  }
}

@media screen and (max-width: 960px) {
  .footer .footer-btn-unit__item a {
    padding: 24px;
  }
}

@media screen and (max-width: 520px) {
  .footer .footer-btn-unit__item a {
    padding: 24px 16px;
  }
}

.footer .footer-btn-unit__item a .footer-btn-inner {
  position: relative;
  padding: 0 0 0 96px;
}

@media screen and (max-width: 1280px) {
  .footer .footer-btn-unit__item a .footer-btn-inner {
    padding: 0 0 0 72px;
  }
}

@media screen and (max-width: 960px) {
  .footer .footer-btn-unit__item a .footer-btn-inner {
    display: block;
    width: 100%;
    padding: 64px 0 0;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer-btn-unit__item a .footer-btn-inner {
    padding: 0 0 0 72px;
    text-align: left;
  }
}

.footer .footer-btn-unit__item a .footer-btn-inner svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
  fill: #7b9e14;
}

@media screen and (max-width: 1280px) {
  .footer .footer-btn-unit__item a .footer-btn-inner svg {
    width: 56px;
    height: 56px;
  }
}

@media screen and (max-width: 960px) {
  .footer .footer-btn-unit__item a .footer-btn-inner svg {
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer-btn-unit__item a .footer-btn-inner svg {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.footer .footer-btn-unit__item a .footer-btn-inner .footer-btn-ttl__main {
  margin-bottom: 4px;
  font-size: 2.4rem;
  line-height: 1.2;
}

@media screen and (max-width: 1280px) {
  .footer .footer-btn-unit__item a .footer-btn-inner .footer-btn-ttl__main {
    margin-bottom: 0;
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer-btn-unit__item a .footer-btn-inner .footer-btn-ttl__main {
    font-size: 1.6rem;
  }
}

.footer .footer-btn-unit__item a .footer-btn-inner .footer-btn-ttl__sub {
  font-family: "Jost", sans-serif;
  font-size: 1.4rem;
  line-height: 1.2;
  color: #7b9e14;
}

@media screen and (max-width: 1280px) {
  .footer .footer-btn-unit__item a .footer-btn-inner .footer-btn-ttl__sub {
    font-size: 1.2rem;
  }
}

.footer .footer-btn-unit__item a .ico-arrow {
  position: absolute;
  right: 24px;
  bottom: 20px;
  width: 24px;
  height: 6px;
  fill: #7b9e14;
}

@media screen and (max-width: 1280px) {
  .footer .footer-btn-unit__item a .ico-arrow {
    right: 12px;
    bottom: 12px;
  }
}

.footer .footer-btn-unit__item a:hover {
  transform: translate(4px, 4px);
  box-shadow: none;
}

.footer .footer-btn-unit__item:nth-child(2) a {
  background: #E2E8F0;
}

.footer .footer-btn-unit__item:nth-child(2) a .footer-btn-inner svg {
  fill: #4977ad;
}

.footer .footer-btn-unit__item:nth-child(2) a .footer-btn-inner .footer-btn-ttl__main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 960px) {
  .footer .footer-btn-unit__item:nth-child(2) a .footer-btn-inner .footer-btn-ttl__main {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer-btn-unit__item:nth-child(2) a .footer-btn-inner .footer-btn-ttl__main {
    justify-content: flex-start;
  }
}

.footer .footer-btn-unit__item:nth-child(2) a .footer-btn-inner .footer-btn-ttl__main span {
  display: inline-block;
  margin-left: 6px;
  font-family: "Jost", sans-serif;
  font-size: 1.4rem;
  color: #4977ad;
}

@media screen and (max-width: 1280px) {
  .footer .footer-btn-unit__item:nth-child(2) a .footer-btn-inner .footer-btn-ttl__main span {
    font-size: 1.2rem;
  }
}

.footer .footer-btn-unit__item:nth-child(2) a .footer-btn-inner .footer-btn-ttl__sub {
  font-size: 1.2rem;
  color: #4977ad;
}

@media screen and (max-width: 1280px) {
  .footer .footer-btn-unit__item:nth-child(2) a .footer-btn-inner .footer-btn-ttl__sub {
    font-size: 1rem;
  }
}

.footer .footer-btn-unit__item:nth-child(2) a .ico-arrow {
  fill: #4977ad;
}

.footer .footer-btn-unit__item:nth-child(3) a {
  background: #def0ed;
}

.footer .footer-btn-unit__item:nth-child(3) a .footer-btn-inner svg {
  fill: #22a8a3;
}

.footer .footer-btn-unit__item:nth-child(3) a .footer-btn-inner .footer-btn-ttl__sub {
  color: #22a8a3;
}

.footer .footer-btn-unit__item:nth-child(3) a .ico-arrow {
  fill: #22a8a3;
}

.footer .footer-unit {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 48px;
}

.footer .footer-unit__info {
  width: 100%;
  max-width: 420px;
}

@media screen and (max-width: 1024px) {
  .footer .footer-unit__info {
    max-width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer-unit__info {
    margin: 0 auto 40px;
  }
}

.footer .footer-unit__info .footer-logo {
  margin-bottom: 20px;
  padding-bottom: 24px;
  max-width: 420px;
  border-bottom: dashed 1px #cccccc;
}

.footer .footer-unit__info .footer-add {
  margin-bottom: 8px;
}

.footer .footer-unit__info .footer-hotline a .footer-hotline-inner {
  position: relative;
  padding-left: 32px;
  font-family: "Jost", sans-serif;
  font-size: 3.2rem;
}

@media screen and (max-width: 520px) {
  .footer .footer-unit__info .footer-hotline a .footer-hotline-inner {
    padding-left: 28px;
    font-size: 2.8rem;
  }
}

.footer .footer-unit__info .footer-hotline a .footer-hotline-inner svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  fill: #231f20;
}

@media screen and (max-width: 520px) {
  .footer .footer-unit__info .footer-hotline a .footer-hotline-inner svg {
    width: 20px;
    height: 20px;
  }
}

.footer .footer-unit__info .footer-hotline a .footer-hotline-inner span {
  display: inline-block;
  transform: translateY(-2px);
  font-size: 1.6rem;
}

@media screen and (max-width: 520px) {
  .footer .footer-unit__info .footer-hotline a .footer-hotline-inner span {
    font-size: 1.4rem;
  }
}

.footer .footer-unit__menu {
  min-width: 510px;
}

@media screen and (max-width: 1024px) {
  .footer .footer-unit__menu {
    min-width: auto;
    max-width: 510px;
    width: calc( 100% - 324px);
  }
}

@media screen and (max-width: 768px) {
  .footer .footer-unit__menu {
    width: 100%;
    margin: 0 auto;
  }
}

.footer .footer-unit__menu .footer-nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .footer .footer-unit__menu .footer-nav {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 520px) {
  .footer .footer-unit__menu .footer-nav {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.footer .footer-unit__menu .footer-nav .footer-nav-list {
  max-width: 240px;
}

@media screen and (max-width: 520px) {
  .footer .footer-unit__menu .footer-nav .footer-nav-list {
    max-width: none;
  }
}

.footer .footer-unit__menu .footer-nav .footer-nav-list__item a {
  display: block;
  padding: 12px 4px;
  font-size: 1.3rem;
}

.footer .footer-unit__menu .footer-nav .footer-nav-list__item a:hover {
  padding-left: 8px;
  padding-right: 0;
}

.footer .footer-unit__menu .footer-nav .footer-nav-list__item:first-child a {
  padding-top: 0;
}

.footer .footer-unit__menu .footer-nav .footer-nav-list__item:not(:last-child) a {
  position: relative;
}

.footer .footer-unit__menu .footer-nav .footer-nav-list__item:not(:last-child) a::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #4977ad 40px, #cccccc 41px);
}

@media screen and (max-width: 520px) {
  .footer .footer-unit__menu .footer-nav .footer-nav-list:not(:first-child) .footer-nav-list__item:first-child a {
    padding-top: 12px;
  }
}

@media screen and (max-width: 520px) {
  .footer .footer-unit__menu .footer-nav .footer-nav-list:not(:first-child) .footer-nav-list__item:first-child a::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #4977ad 40px, #cccccc 41px);
  }
}

.footer .footer-unit__menu .footer-bnr a {
  display: block;
  margin-right: auto;
  max-width: 260px;
}

@media screen and (max-width: 768px) {
  .footer .footer-unit__menu .footer-bnr a {
    margin: 0 auto;
  }
}

.footer .pagetop {
  position: fixed;
  right: 40px;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 36px;
  border-radius: 2px 2px 0 0;
  background: #1aa9db;
  color: #ffffff;
  font-family: "Jost", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1;
}

@media screen and (max-width: 960px) {
  .footer .pagetop {
    right: 24px;
  }
}

@media screen and (max-width: 768px) {
  .footer .pagetop {
    right: 16px;
    height: 28px;
  }
}

.footer .copy {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 10px 16px;
  border-top: solid 1px #1aa9db;
  font-size: 1.2rem;
  text-align: center;
  color: #999999;
}

.footer .copy .copy-poweredby {
  display: inline-block;
  width: 45px;
  height: 10px;
  margin-left: 4px;
  vertical-align: baseline;
}

.notfound-txt {
  margin-bottom: calc(8px * 3);
  text-align: center;
}

@media screen and (max-width: 960px) {
  .notfound-txt {
    margin-bottom: calc((8px * 3) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .notfound-txt {
    margin-bottom: calc((8px * 3) * 0.5);
  }
}

.notfound-message {
  max-width: 640px;
  margin: 0 auto;
  margin-bottom: calc(8px * 4);
  padding: calc(8px * 4);
  border: solid 1px #cccccc;
}

@media screen and (max-width: 960px) {
  .notfound-message {
    margin-bottom: calc((8px * 4) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .notfound-message {
    margin-bottom: calc((8px * 4) * 0.5);
  }
}

@media screen and (max-width: 960px) {
  .notfound-message {
    padding: calc((8px * 4) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .notfound-message {
    padding: calc((8px * 4) * 0.5);
  }
}

.lower-page .hero {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  margin-bottom: calc(8px * 2);
  background: #cccccc;
}

@media screen and (max-width: 960px) {
  .lower-page .hero {
    margin-bottom: calc((8px * 2) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .lower-page .hero {
    margin-bottom: calc((8px * 2) * 0.5);
  }
}

.lower-page .breadcrumb {
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: calc(8px * 10);
}

@media screen and (max-width: 1280px) {
  .lower-page .breadcrumb {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 960px) {
  .lower-page .breadcrumb {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .lower-page .breadcrumb {
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media screen and (max-width: 960px) {
  .lower-page .breadcrumb {
    margin-bottom: calc((8px * 10) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .lower-page .breadcrumb {
    margin-bottom: calc((8px * 10) * 0.5);
  }
}

.lower-page .breadcrumb__item {
  display: inline;
}

.lower-page .breadcrumb__item:not(:nth-last-of-type(1)) {
  position: relative;
}

.lower-page .breadcrumb__item:not(:nth-last-of-type(1))::after {
  content: '>';
  padding: 0 4px 0 8px;
}

.lower-page .breadcrumb__item a {
  text-decoration: underline;
}

.lower-page .breadcrumb__item a:hover {
  text-decoration: none;
}

.lower-page .pagettl {
  color: #ffffff;
  font-size: 4rem;
}

.lower-page .headline {
  margin-bottom: calc(8px * 4);
  font-size: 3.2rem;
}

@media screen and (max-width: 960px) {
  .lower-page .headline {
    margin-bottom: calc((8px * 4) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .lower-page .headline {
    margin-bottom: calc((8px * 4) * 0.5);
  }
}

.lower-page .ttl-l {
  margin-bottom: calc(8px * 3);
  font-size: 2.8rem;
}

@media screen and (max-width: 960px) {
  .lower-page .ttl-l {
    margin-bottom: calc((8px * 3) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .lower-page .ttl-l {
    margin-bottom: calc((8px * 3) * 0.5);
  }
}

.lower-page .ttl-m {
  margin-bottom: calc(8px * 2);
  font-size: 2.4rem;
}

@media screen and (max-width: 960px) {
  .lower-page .ttl-m {
    margin-bottom: calc((8px * 2) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .lower-page .ttl-m {
    margin-bottom: calc((8px * 2) * 0.5);
  }
}

.lower-page .ttl-s {
  margin-bottom: calc(8px * 1);
  font-size: 2rem;
}

@media screen and (max-width: 960px) {
  .lower-page .ttl-s {
    margin-bottom: calc((8px * 1) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .lower-page .ttl-s {
    margin-bottom: calc((8px * 1) * 0.5);
  }
}

.txt.--center {
  text-align: center;
}

.txt.--right {
  text-align: right;
}

.lead-txt {
  font-size: 2rem;
}

.lead-txt.--center {
  text-align: center;
}

.lead-txt.--right {
  text-align: right;
}

.link-txt {
  text-decoration: underline;
}

.link-txt:hover {
  text-decoration: none;
}

.note-txt {
  margin-left: 1em;
  font-size: 1.4rem;
  text-indent: -1em;
}

.note-txt::before {
  content: '※';
}

.fc-red {
  color: #e63502;
}

.fc-aqu {
  color: #1aa9db;
}

.fc-l-blu {
  color: #E2E8F0;
}

.fc-blu {
  color: #4977ad;
}

.fc-d-blu {
  color: #153875;
}

.fc-l-grn {
  color: #def0ed;
}

.fc-grn {
  color: #22a8a3;
}

.fc-d-grn {
  color: #066f68;
}

.fc-l-yel-grn {
  color: #f3f6e0;
}

.fc-yel-grn {
  color: #7b9e14;
}

.fc-l-orn {
  color: #fff3dd;
}

.fc-orn {
  color: #f8b726;
}

.fc-d-orn {
  color: #f08306;
}

.fc-blk {
  color: #231f20;
}

.fc-wht {
  color: #ffffff;
}

.fc-p-gry {
  color: #fafafa;
}

.fc-l-gry {
  color: #eeeeee;
}

.fc-gry {
  color: #cccccc;
}

.fc-d-gry {
  color: #999999;
}

.list__item {
  position: relative;
  padding-left: 16px;
}

.list__item:not(:nth-last-of-type(1)) {
  margin-bottom: 8px;
}

.list__item::before {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #231f20;
  border-radius: 50%;
}

.num-list {
  counter-reset: list-num;
}

.num-list__item {
  margin-left: 1em;
  text-indent: -1em;
}

.num-list__item::before {
  counter-increment: list-num;
  content: counter(list-num) ".";
}

.note-list__item {
  margin-left: 1em;
  font-size: 1.2rem;
  text-indent: -1em;
}

.note-list__item::before {
  content: '※';
}

.btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}

.btn.--left {
  justify-content: flex-start;
}

.btn.--right {
  justify-content: flex-end;
}

.btn a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 300px;
  min-height: 40px;
  padding: 9px 64px 7px 8px;
  background: #ffffff;
  border: solid 1px #231f20;
  border-radius: 2px;
  color: #231f20;
  font-size: 1.4rem;
  line-height: 1.2;
}

@media screen and (max-width: 520px) {
  .btn a {
    padding: 9px 40px 7px 8px;
  }
}

.btn a .ico-arrow {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 6px;
  fill: #231f20;
}

@media screen and (max-width: 520px) {
  .btn a .ico-arrow {
    width: 20px;
    height: 5px;
    right: 12px;
  }
}

.btn a:hover {
  background: #231f20;
  color: #ffffff;
}

.btn a:hover .ico-arrow {
  fill: #ffffff;
}

.btn a.bc-red {
  background: #e63502;
}

.btn a.bc-red:hover {
  background: #ffffff;
  color: #e63502;
}

.btn a.bc-aqu {
  background: #1aa9db;
}

.btn a.bc-aqu:hover {
  background: #ffffff;
  color: #1aa9db;
}

.btn a.bc-l-blu {
  background: #E2E8F0;
}

.btn a.bc-l-blu:hover {
  background: #ffffff;
  color: #E2E8F0;
}

.btn a.bc-blu {
  background: #4977ad;
}

.btn a.bc-blu:hover {
  background: #ffffff;
  color: #4977ad;
}

.btn a.bc-d-blu {
  background: #153875;
}

.btn a.bc-d-blu:hover {
  background: #ffffff;
  color: #153875;
}

.btn a.bc-l-grn {
  background: #def0ed;
}

.btn a.bc-l-grn:hover {
  background: #ffffff;
  color: #def0ed;
}

.btn a.bc-grn {
  background: #22a8a3;
}

.btn a.bc-grn:hover {
  background: #ffffff;
  color: #22a8a3;
}

.btn a.bc-d-grn {
  background: #066f68;
}

.btn a.bc-d-grn:hover {
  background: #ffffff;
  color: #066f68;
}

.btn a.bc-l-yel-grn {
  background: #f3f6e0;
}

.btn a.bc-l-yel-grn:hover {
  background: #ffffff;
  color: #f3f6e0;
}

.btn a.bc-yel-grn {
  background: #7b9e14;
}

.btn a.bc-yel-grn:hover {
  background: #ffffff;
  color: #7b9e14;
}

.btn a.bc-l-orn {
  background: #fff3dd;
}

.btn a.bc-l-orn:hover {
  background: #ffffff;
  color: #fff3dd;
}

.btn a.bc-orn {
  background: #f8b726;
}

.btn a.bc-orn:hover {
  background: #ffffff;
  color: #f8b726;
}

.btn a.bc-d-orn {
  background: #f08306;
}

.btn a.bc-d-orn:hover {
  background: #ffffff;
  color: #f08306;
}

.btn a.bc-blk {
  background: #231f20;
}

.btn a.bc-blk:hover {
  background: #ffffff;
  color: #231f20;
}

.btn a.bc-wht {
  background: #ffffff;
}

.btn a.bc-wht:hover {
  background: #ffffff;
  color: #ffffff;
}

.btn a.bc-p-gry {
  background: #fafafa;
}

.btn a.bc-p-gry:hover {
  background: #ffffff;
  color: #fafafa;
}

.btn a.bc-l-gry {
  background: #eeeeee;
}

.btn a.bc-l-gry:hover {
  background: #ffffff;
  color: #eeeeee;
}

.btn a.bc-gry {
  background: #cccccc;
}

.btn a.bc-gry:hover {
  background: #ffffff;
  color: #cccccc;
}

.btn a.bc-d-gry {
  background: #999999;
}

.btn a.bc-d-gry:hover {
  background: #ffffff;
  color: #999999;
}

.pagetop {
  right: 20px;
  bottom: calc( 0px + env(safe-area-inset-bottom));
  width: 64px;
  height: 64px;
  cursor: pointer;
  z-index: 9900;
}

.tbl table {
  width: 100%;
  border-top: solid 1px #cccccc;
  border-left: solid 1px #cccccc;
}

.tbl table thead tr th {
  padding: 16px;
  background: #8e8083;
  border-right: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
  color: #231f20;
}

@media screen and (max-width: 960px) {
  .tbl table thead {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .tbl table tbody {
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .tbl table tbody tr {
    display: block;
  }
}

.tbl table tbody tr th,
.tbl table tbody tr td {
  padding: 16px;
  border-right: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
}

@media screen and (max-width: 960px) {
  .tbl table tbody tr th,
  .tbl table tbody tr td {
    display: block;
  }
}

.tbl table tbody tr th {
  background: #eeeeee;
}

@media screen and (max-width: 960px) {
  .tbl table tbody tr td::before {
    content: attr(tbl-data);
    display: block;
    color: #231f20;
  }
}

.scroll-tbl {
  overflow-x: auto;
}

.scroll-tbl table {
  width: 100%;
  border-top: solid 1px #cccccc;
  border-left: solid 1px #cccccc;
}

.scroll-tbl table thead tr th {
  padding: 16px;
  background: #8e8083;
  border-right: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
  color: #231f20;
  white-space: nowrap;
}

.scroll-tbl table tbody tr th,
.scroll-tbl table tbody tr td {
  padding: 16px;
  border-right: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
}

.scroll-tbl table tbody tr th {
  background: #eeeeee;
}

.hours-tbl table {
  width: 100%;
  border-top: solid 1px #cccccc;
  border-left: solid 1px #cccccc;
}

.hours-tbl table thead tr th {
  padding: 16px;
  background: #8e8083;
  border-right: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
  color: #231f20;
}

@media screen and (max-width: 960px) {
  .hours-tbl table thead {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .hours-tbl table tbody {
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .hours-tbl table tbody tr {
    display: block;
  }
}

.hours-tbl table tbody tr th,
.hours-tbl table tbody tr td {
  padding: 16px;
  border-right: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
}

@media screen and (max-width: 960px) {
  .hours-tbl table tbody tr th,
  .hours-tbl table tbody tr td {
    display: block;
  }
}

.hours-tbl table tbody tr th {
  background: #eeeeee;
}

@media screen and (max-width: 960px) {
  .hours-tbl table tbody tr td::before {
    content: attr(tbl-data);
    display: block;
    color: #231f20;
  }
}

.frame {
  padding: 16px;
  border: solid 1px #cccccc;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  display: block;
  position: relative;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}

.slick-track:before, .slick-track:after {
  content: '';
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.modal .modal-switch {
  display: none;
}

.modal-overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9997;
  width: 100vw;
  height: 100vh;
  background: #231f20;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.modal-switch:checked ~ .modal-overlay {
  opacity: 0.3;
  visibility: visible;
}

.modal-content {
  opacity: 0;
  visibility: hidden;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9998;
  width: calc(100% - 80px);
  max-width: 900px;
  max-height: calc(100% - 80px);
  padding: 56px 24px 24px;
  background: #ffffff;
  transform: translate(-50%, -50%) scale(0);
}

@media screen and (max-width: 1280px) {
  .modal-content {
    width: calc(100% - 64px);
  }
}

@media screen and (max-width: 960px) {
  .modal-content {
    width: calc(100% - 48px);
    padding: 48px 20px 20px;
  }
}

@media screen and (max-width: 520px) {
  .modal-content {
    width: calc(100% - 32px);
    padding: 40px 16px 16px;
  }
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-content img {
  width: auto;
  max-height: 100%;
  margin: 0 auto;
}

.modal-switch:checked ~ .modal-content {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity .3s,  visibility .3s, transform 0s;
}

.modal .modal-close {
  position: absolute;
  top: 16px;
  right: 24px;
  z-index: 9999;
  font-size: 3.2rem;
  line-height: 32px;
  transition: 0.3s ease-out;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .modal .modal-close {
    right: 20px;
    font-size: 2.4rem;
    line-height: 24px;
  }
}

@media screen and (max-width: 520px) {
  .modal .modal-close {
    top: 12px;
    right: 16px;
    font-size: 2rem;
    line-height: 20px;
  }
}

.modal .modal-close:hover {
  opacity: 0.8;
}

.acd summary {
  position: relative;
  padding: calc(8px * 3);
  list-style: none;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .acd summary {
    padding: calc((8px * 3) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .acd summary {
    padding: calc((8px * 3) * 0.5);
  }
}

.acd summary::-webkit-details-marker {
  display: none;
}

.acd summary::after {
  content: '+';
  position: absolute;
  top: 50%;
  right: 30px;
  font-size: 3.2rem;
  line-height: 32px;
  transform: translateY(-50%);
  transition: 0.3s ease-out;
}

.acd .acd-content {
  padding: calc(8px * 3);
  background: #eeeeee;
}

@media screen and (max-width: 960px) {
  .acd .acd-content {
    padding: calc((8px * 3) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .acd .acd-content {
    padding: calc((8px * 3) * 0.5);
  }
}

.acd[open] summary::after {
  transform: translateY(-50%) rotate(45deg);
}

.acd[open] .acd-content {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.writer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px;
  border: solid 2px #eeeeee;
}

.writer .writer-vsl {
  width: 96px;
}

@media screen and (max-width: 960px) {
  .writer .writer-vsl {
    width: 88px;
  }
}

@media screen and (max-width: 768px) {
  .writer .writer-vsl {
    width: 80px;
  }
}

@media screen and (max-width: 520px) {
  .writer .writer-vsl {
    width: 104px;
    margin: 0 auto 16px;
  }
}

.writer .writer-dtl {
  width: calc(100% - 112px);
}

@media screen and (max-width: 960px) {
  .writer .writer-dtl {
    width: calc(100% - 104px);
  }
}

@media screen and (max-width: 768px) {
  .writer .writer-dtl {
    width: calc(100% - 96px);
  }
}

@media screen and (max-width: 520px) {
  .writer .writer-dtl {
    width: 100%;
  }
}

.writer .writer-dtl__ttl {
  display: inline-block;
  margin-bottom: 4px;
  padding: 2px 8px;
  background: #231f20;
  color: #ffffff;
}

@media screen and (max-width: 520px) {
  .writer .writer-dtl__ttl {
    width: 100%;
    margin-bottom: 8px;
    text-align: center;
  }
}

.writer .writer-dtl__name {
  margin-bottom: 4px;
}

@media screen and (max-width: 520px) {
  .writer .writer-dtl__name {
    text-align: center;
  }
}

.writer .writer-dtl__name a {
  color: #231f20;
}

.writer .writer-dtl__company {
  font-size: 1.4rem;
}

@media screen and (max-width: 520px) {
  .writer .writer-dtl__company {
    text-align: center;
  }
}

.writer .writer-dtl__post {
  margin-bottom: 8px;
  font-size: 1.4rem;
}

@media screen and (max-width: 520px) {
  .writer .writer-dtl__post {
    text-align: center;
  }
}

.writer .writer-dtl__txt {
  font-size: 1.4rem;
}
