//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// 初期設定
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//--------------------------------------------------//
// 基本設定
//--------------------------------------------------//
  :focus-visible {
    outline: 3px solid #0044ff;
    outline-offset: -1px;
  }
  html,
  body {
    width: 100%;
    height: 100%;
    color: $blk;
    background: $tra;
  }
  html {
    font-size: 62.5%;
  }
  body {
    font-size: $fs-m;
    letter-spacing: 0.05em;
    line-height: 1.6;
    font-family: $ff-base;
    @include break(m) {
      letter-spacing: 0;
    }
    @include break(s) {
      font-size: $fs-s;
      line-height: 1.4;
      letter-spacing: 0;
    }
    &.page-overflow {
      overflow: hidden;
      height: 100%;
    }
  }
  a {
    overflow-wrap: break-word;
    color: $blk;
    text-decoration: none;
    transition: 0.3s ease-out;
    &:hover,
    &:active,
    &:visited {
      color: $blk;
    }
    &[href*="tel:"] {
      text-decoration: none;
      pointer-events: none;
      cursor: default;
      @include break(m) {
        pointer-events: auto;
        cursor: pointer;
      }
    }
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    &:not(.no-fit) {    
      width: 100%;
    }
  }

//--------------------------------------------------//
// 端末ごとの表示・非表示の設定
//--------------------------------------------------//
  // 1280px以上
  @media screen and (min-width: $xl + 1) {
    .view-xl { display: none !important; }
  }
  // 1280px以下
  @media screen and (max-width: $xl) {
    .none-xl { display: none !important; }
  }
  // 1024px以上
  @media screen and (min-width: $l + 1) {
    .view-l { display: none !important; }
  }
  // 1024px以下
  @media screen and (max-width: $l) {
    .none-l { display: none !important; }
  }
  // 960px以上
  @media screen and (min-width: $m + 1) {
    .view-m { display: none !important; }
  }
  // 960px以下
  @media screen and (max-width: $m) {
    .none-m { display: none !important; }
  }
  // 768px以上
  @media screen and (min-width: $s + 1) {
    .view-s { display: none !important; }
  }
  // 768px以下
  @media screen and (max-width: $s) {
    .none-s { display: none !important; }
  }
  // 520px以上
  @media screen and (min-width: $xs + 1) {
    .view-xs { display: none !important; }
  }
  // 520px以下
  @media screen and (max-width: $xs) {
    .none-xs { display: none !important; }
  }

//--------------------------------------------------//
// サイト幅
//--------------------------------------------------//
  .wrap {
    @include wrap(1180px);
  }
  .inner {
    @include wrap;
  }

//--------------------------------------------------//
// 余白
//--------------------------------------------------//
  // セクションごとの余白
  .mb-sect {
    @include mb(x10); // 80px
  }
  // 各方向の外余白設定
  @for $i from 1 through 20 {
    .mr-x#{$i} {
      margin-right: $space * $i;
      @include break(m) {
        margin-right: $space * $i * 0.75;
      }
      @include break(xs) {
        margin-right: $space * $i * 0.5;
      }
    }
    .mb-x#{$i} {
      margin-bottom: $space * $i;
      @include break(m) {
        margin-bottom: $space * $i * 0.75;
      }
      @include break(xs) {
        margin-bottom: $space * $i * 0.5;
      }
    }
    .ml-x#{$i} {
      margin-left: $space * $i;
      @include break(m) {
        margin-left: $space * $i * 0.75;
      }
      @include break(xs) {
        margin-left: $space * $i * 0.5;
      }
    }
  }
  // 各方向の内余白設定
  @for $i from 1 through 20 {
    .pt-x#{$i} {
      padding-top: $space * $i;
      @include break(m) {
        padding-top: $space * $i * 0.75;
      }
      @include break(xs) {
        padding-top: $space * $i * 0.5;
      }
    }
    .pr-x#{$i} {
      padding-right: $space * $i;
      @include break(m) {
        padding-right: $space * $i * 0.75;
      }
      @include break(xs) {
        padding-right: $space * $i * 0.5;
      }
    }
    .pb-x#{$i} {
      padding-bottom: $space * $i;
      @include break(m) {
        padding-bottom: $space * $i * 0.75;
      }
      @include break(xs) {
        padding-bottom: $space * $i * 0.5;
      }
    }
    .pl-x#{$i} {
      padding-left: $space * $i;
      @include break(m) {
        padding-left: $space * $i * 0.75;
      }
      @include break(xs) {
        padding-left: $space * $i * 0.5;
      }
    }
  }