//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// リセットcss
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//--------------------------------------------------//
// 要素のフォントサイズやマージン・パディングをリセット
//--------------------------------------------------//
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

//--------------------------------------------------//
// HTML5の要素をブロック要素に設定
//--------------------------------------------------//
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

//--------------------------------------------------//
// 行の高さをフォントサイズと同じ高さに設定
//--------------------------------------------------//
body {
  line-height: 1;
}

//--------------------------------------------------//
// リストのマーカーを非表示
//--------------------------------------------------//
  ol, ul {
    list-style: none;
  }

//--------------------------------------------------//
// 引用符を非表示
//--------------------------------------------------//
  blockquote, q {
    quotes: none;
  }

//--------------------------------------------------//
// blockquote、q要素の前後にコンテンツを追加しないように設定
//--------------------------------------------------//
  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }
  table {
    border-collapse:
    collapse;
    border-spacing: 0;
  }
  input, select, button, textarea {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
  select::-ms-expand {
    display: none;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }