//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// フッター
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  .footer {
    position: relative;
    // ボタン群 ----- //
    .footer-btn-unit {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      padding: 32px 24px 48px;
      @include break(m) {
        gap: 16px;
      }
      @include break(s) {
        grid-template-columns: 1fr;
        max-width: 640px;
        margin: 0 auto;
      }
      @include break(xs) {
        gap: 10px;
      }
      &__item {
        a {
          position: relative;
          @include flex("", center);
          padding: 40px 0 40px 40px;
          background: $l-yel-grn;
          border-radius: 4px;
          box-shadow: 5px 5px 12px rgba($d-blu, .06);
          @include break(xl) {
            padding: 32px 16px;
          }
          @include break(m) {
            padding: 24px;
          }
          @include break(xs) {
            padding: 24px 16px;
          }
          .footer-btn-inner {
            position: relative;
            padding: 0 0 0 96px;
            @include break(xl) {
              padding: 0 0 0 72px;
            }
            @include break(m) {
              display: block;
              width: 100%;
              padding: 64px 0 0;
              text-align: center;
            }
            @include break(s) {
              padding: 0 0 0 72px;
              text-align: left;
            }
            svg {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 80px;
              height: 80px;
              fill: $yel-grn;
              @include break(xl) {
                width: 56px;
                height: 56px;
              }
              @include break(m) {
                left: 50%;
                transform: translateX(-50%);
                top: 0;
              }
              @include break(s) {
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .footer-btn-ttl {
              &__main {
                margin-bottom: 4px;
                font-size: $fs-xl;
                line-height: 1.2;
                @include break(xl) {
                  margin-bottom: 0;
                  font-size: $fs-l;
                }
                @include break(s) {
                  font-size: $fs-m;
                }
              }
              &__sub {
                font-family: $ff-en;
                font-size: $fs-s;
                line-height: 1.2;
                color: $yel-grn;
                @include break(xl) {
                  font-size: $fs-xs;
                }
              }
            }
          }
          .ico-arrow {
            position: absolute;
            right: 24px;
            bottom: 20px;
            width: 24px;
            height: 6px;
            fill: $yel-grn;
            @include break(xl) {
              right: 12px;
              bottom: 12px;
            }
          }
          &:hover {
            transform: translate(4px,4px);
            box-shadow: none;
          }
        }
        //採用募集
        &:nth-child(2) {
          a {
            background: $l-blu;
            .footer-btn-inner {
              svg {
                fill: $blu;
              }
              .footer-btn-ttl {
                &__main {
                  @include flex("", center);
                  @include break(m) {
                    justify-content: center;
                  }
                  @include break(s) {
                    justify-content: flex-start;
                  }
                  span {
                    display: inline-block;
                    margin-left: 6px;
                    font-family: $ff-en;
                    font-size: $fs-s;
                    color: $blu;
                    @include break(xl) {
                      font-size: $fs-xs;
                    }
                  }
                }
                &__sub {
                  font-size: $fs-xs;
                  color: $blu;
                  @include break(xl) {
                    font-size: $fs-xxs;
                  }
                }
              }
            }
            .ico-arrow {
              fill: $blu;
            }
          }
        }
        //寄付お願い
        &:nth-child(3) {
          a {
            background: $l-grn;
            .footer-btn-inner {
              svg {
                fill: $grn;
              }
              .footer-btn-ttl {
                &__sub {
                  color: $grn;
                }
              }
            }
            .ico-arrow {
              fill: $grn;
            }
          }
        }
      }
    }
    // 病院情報とメニュー ----- //
    .footer-unit {
      @include flex(space-between);
      margin-bottom: 48px;
      // 病院情報
      &__info {
        width: 100%;
        max-width: 420px;
        @include break(l) {
          max-width: 300px;
        }
        @include break(s) {
          margin: 0 auto 40px;
        }
        .footer-logo {
          margin-bottom: 20px;
          padding-bottom: 24px;
          max-width: 420px;
          border-bottom: dashed 1px $gry;
        }
        .footer-add {
          margin-bottom: 8px;
        }
        .footer-hotline {
          a {
            .footer-hotline-inner {
              position: relative;
              padding-left: 32px;
              font-family: $ff-en;
              font-size: $fs-3xl;
              @include break(xs) {
                padding-left: 28px;
                font-size: $fs-xxl;
              }
              svg {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
                fill: $blk;
                @include break(xs) {
                  width: 20px;
                  height: 20px;
                }
              }
              span {
                display: inline-block;
                transform: translateY(-2px);
                font-size: $fs-m;
                @include break(xs) {
                  font-size: $fs-s;
                }
              }
            }
          }
        }
      }
      // メニュー
      &__menu {
        min-width: 510px;
        @include break(l) {
          min-width: auto;
          max-width: 510px;
          width: calc( 100% - 324px );
        }
        @include break(s) {
          width: 100%;
          margin: 0 auto;
        }
        .footer-nav {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
          margin-bottom: 6px;
          @include break(s) {
            margin-bottom: 32px;
          }
          @include break(xs) {
            grid-template-columns: 1fr;
            gap: 0;
          }
          .footer-nav-list {
            max-width: 240px;
            @include break(xs) {
              max-width: none;
            }
            &__item {
              a {
                display: block;
                padding: 12px 4px;
                font-size: 1.3rem;
                &:hover {
                  padding-left: 8px;
                  padding-right: 0;
                }
              }
              &:first-child {
                a {
                  padding-top: 0;
                }
              }
              &:not(:last-child) {
                a {
                  position: relative;
                  &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient( to right, $blu 40px, $gry 41px);
                  }
                }
              }
            }
            &:not(:first-child) {
              .footer-nav-list__item {
                &:first-child {
                  a {
                    @include break(xs) {
                      padding-top: 12px;
                    }
                    &::before {
                      @include break(xs) {
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 1px;
                        background: linear-gradient( to right, $blu 40px, $gry 41px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .footer-bnr {
          a {
            display: block;
            margin-right: auto;
            max-width: 260px;
            @include break(s) {
              margin: 0 auto;
            }
          }
        }
      }
    }
    // ページトップ ----- //
    .pagetop {
      position: fixed;
      right: 40px;
      bottom: 0;
      @include flex(center, center);
      width: 140px;
      height: 36px;
      border-radius: 2px 2px 0 0;
      background: $aqu;
      color: $wht;
      font-family: $ff-en;
      font-size: $fs-xs;
      text-transform: uppercase;
      line-height: 1;
      @include break(m) {
        right: 24px;
      }
      @include break(s) {
        right: 16px;
        height: 28px;
      }
    }
    // コピーライト ----- //
    .copy {
      @include flex(center, center);
      height: 40px;
      padding: 10px 16px;
      border-top: solid 1px $aqu;
      font-size: $fs-xs;
      text-align: center;
      color: $d-gry;
      .copy-poweredby {
        display: inline-block;
        width: 45px;
        height: 10px;
        margin-left: 4px;
        vertical-align: baseline;
      }
    }
  }